import { Row, Col, Modal, Button, Typography, Select, Popover } from "antd";
import Text from "react";
import { React } from "react";
import { useEffect, useState, useRef } from "react";
import windlogo from "../../../images/vayuLogo.svg";
import earthlogo from "../../../images/prithviLogo.svg";
import firelogo from "../../../images/agniLogo.svg";
import spacelogo from "../../../images/akashLogo.svg";
import waterlogo from "../../../images/jalLogo.svg";
import hearticon from "../../../images/heart icon.svg";
import redhearticon from "../../../images/redhearticon.svg";
import heartImage from "../../../images/heartImage.svg";
import PublisherIcon from "../../../images/PublisherIcon.svg";
import arrowRight from "../../../images/arrowRight.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OverviewModal from "./overviewModal";
import "./overView.css";
import Api from "services/Api";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../store";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "@react-hook/window-size";
import winner from "../../../images/winner.png";
import Lottie from "react-lottie";
import { overViewActions } from "./store";
import { Spin } from "antd";
import moment from "moment";
import animationData from "../../../lotties/data";
import WinnerModal from "./WinnerModal";
import MultipleWinnerModal from "./MultipleWinnerModal";
import YearDropdown from "component/YearDropdown";
import SliderImage from "component/SliderImage";
import classes from './modalDescription.module.css';
import MainHeader from "component/Layout/MainHeader";
import first from '../../../images/first.svg'
import Second from '../../../images/Second.svg'
import third from '../../../images/third.svg'
import Rank1 from '../../../images/Rank 1.svg'
import Rank2 from '../../../images/Rank 2.svg'
import Rank3 from '../../../images/Rank 3.svg'
import x from '../../../images/x.svg'
var sessionstorage = require("sessionstorage");
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
function Overview() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const { likes } = useSelector((state) => state.overView);
  const { toggle } = useSelector((state) => state.house);
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const getDate = (str) => {
    const date = new Date(str);
    let datestr = date.toLocaleString();
    const date1 = date.toLocaleTimeString();
    const date3 = date1?.slice(7);

    const months = [" ", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];
    return (date.getUTCDate() + " " + months[date.getUTCMonth()] + " " + date.getFullYear() + " at " + date.getUTCHours() + ":" + date.getUTCMinutes());
  };
  const d = new Date();
  const [multiplewinnerModalOpen, setmultiplewinnerModalOpen] = useState(false)
  const [winnerModalOpen, setwinnerModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [modalId, setModalId] = useState("");
  const [open, setOpen] = useState(false);
  const [isIconClicked, setIconClicked] = useState(false);
  const [houseData, setHouseData] = useState([]);
  const [futureEvent, setFutureEvent] = useState([]);
  const [whatHappen, setWhatHappen] = useState([]);
  const [ellipsis, setEllipsis] = useState(true);
  const { Paragraph, Text } = Typography;
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [winnerHouse, setWinnerHouse] = useState("");
  const [winnerPoints, setWinnerPoints] = useState("")
  // const [housePoints, setHousePoints] = useState("")
  const [participants, setParticipants] = useState("")
  const [powerHouse, setPowerHouse] = useState("");
  const [AllhousePoints, setAllHousePoints] = useState([])
  const [showAllData, setShowAllData] = useState(false);
  const [powerHouseArray, setPowerHouseArray] = useState([]);
  const [winnerHouseArray, setWinnerHouseArray] = useState([]);
  const [winnerPointsArray, setWinnerPointsArray] = useState([])
  const { year } = useSelector(state => state.auth)
  console.log("yy", year)
  const months = [" ", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec",];
  const handleViewAllClick = () => {
    console.log("viewall")
    setShowAllData(true);
  };
  const toggleModal = () => {
    setShowAllData(!showAllData);
  };
  const handlemodalcancel = () => {
    setIsModalOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(true);
  // const AllhousePoints = [
  //   { id: 1, name: 'VAYU', captain: 'Captain Vayu', viceCaptain: 'Vice Captain Vayu', team: 7, points: 10 },
  //   { id: 4, name: 'JAL', captain: 'Captain Jal', viceCaptain: 'Vice Captain Jal', team: 6, points: 9 },
  //   { id: 5, name: 'AGNI', captain: 'Captain Agni', viceCaptain: 'Vice Captain Agni', team: 7, points: 4 },
  //   { id: 2, name: 'PRITHVI', captain: 'Captain prithvi', viceCaptain: 'Vice Captain Prithvi', team: 6, points: 1 },
  //   { id: 6, name: 'AKASH', captain: 'Captain Akash', viceCaptain: 'Vice Captain Akash', team: 5, points: 2 },
  // ];


  console.log("AllhousePoints", AllhousePoints)
  const sortedHouses = AllhousePoints?.sort((a, b) => b.points - a.points);
  let currentRank = 0;
  let currentRankShowAll = 0;
  let previousPoints = null;
  console.log("sortedHouses", sortedHouses)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [showAll, setShowAll] = useState(false);
  const [displayedCount, setDisplayedCount] = useState(2);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setDisplayedCount(showAll ? 2 : whatHappen.length);
  };
  useEffect(() => {
    if (token) {
      Api.get("/ibelong-houses/overview").params({ queryYear: year }).send((response) => {
        let value = [];
        let list1 = [];
        let list2 = [];
        let list3 = [];
        console.log("loggggg kkk",year,typeof(year), response?.houses);

        if(year===2023){
          // for year 2023 it was asked to show the points hardcoded on 30 october-2023.
          const houseP=[]
          response?.houses?.forEach((item,id)=>{
            let obj={...item}
              if(item?.id===1){
                  obj.points=1520;
              }
              else if(item?.id===2){
                  obj.points=1390;
              }
              else if(item?.id===4){
                  obj.points=1140;
              }
              else if(item?.id===5){
                  obj.points=1360;
              }
              else if(item?.id===6){
                  obj.points=1360;
              }
              houseP.push(obj);
          })

          console.log("loggggg cccccccc",houseP)
          setAllHousePoints(houseP)
      }
      else if(year!==2023){
        setAllHousePoints(response?.houses)
      }
        

        if (response !== undefined) {
          dispatch(authActions.updateHouses(response.houses));
          list1 = response?.futureEvents?.map((element) => {
            value.push(element);
            return element;
          });
          list2 = response?.houses?.map((element) => {
            value.push(element);
            return element;
          });
          list3 = response?.whatsHappen?.map((element) => {
            value.push(element);
            return element;
          });
          setFutureEvent(list1);
          setHouseData(list2);
          setWhatHappen(list3);

        }
      });
    }
  }, [token, likes, toggle, year]);


  useEffect(() => {
    if (token) {
      Api.get("/ibelong-houses/titles").params({ queryYear: year }).send((response) => {
        if (response) {
          console.log("response", response);
          const allStatsForParticipants = response?.allStatsForParticipants_HouseWise;
          const allStatsForHousePoint = response?.allStatsForPoints_HouseWise;
          // const allStatsForHousePoint = [
          //   { id: 1, name: 'VAYU', captain: 'Captain Vayu', viceCaptain: 'Vice Captain Vayu', team: 7, points: 10 },
          //   { id: 4, name: 'JAL', captain: 'Captain Jal', viceCaptain: 'Vice Captain Jal', team: 6, points: 9 },
          //   { id: 5, name: 'AGNI', captain: 'Captain Agni', viceCaptain: 'Vice Captain Agni', team: 7, points: 4 },
          //   { id: 2, name: 'PRITHVI', captain: 'Captain prithvi', viceCaptain: 'Vice Captain Prithvi', team: 6, points: 1 },
          //   { id: 6, name: 'AKASH', captain: 'Captain Akash', viceCaptain: 'Vice Captain Akash', team: 5, points: 2 },
          // ];
          console.log("sssmaxpoints", allStatsForParticipants)
          const maxParticipants = Math.max(...allStatsForParticipants.map(item => item.numberOfParticipants));
          const maxCount = allStatsForParticipants.filter(item => item.numberOfParticipants === maxParticipants).length;
          const maxHousePoint = Math.max(...allStatsForHousePoint.map(item => item.housePoints));

          const housesWithMaxParticipants = maxParticipants == 0 ? [] : allStatsForParticipants
            .filter(item => item.numberOfParticipants === maxParticipants)
            .map(item => item.houseName);

          const housesWithMaxHousePoint = maxHousePoint == 0 ? [] : allStatsForHousePoint
            .filter(item => item.housePoints === maxHousePoint)
            .map(item => item.housePoints);

          const housesWithMaxHouseName = maxHousePoint == 0 ? [] : allStatsForHousePoint
            .filter(item => item.housePoints === maxHousePoint)
            .map(item => item.houseName);

          setWinnerPointsArray(housesWithMaxHousePoint);
          setWinnerHouseArray(housesWithMaxHouseName);
          setPowerHouseArray(housesWithMaxParticipants);
          if (housesWithMaxHousePoint.length > 1) {
            setWinnerPointsArray(housesWithMaxHousePoint);
          } else {
            setWinnerPoints(housesWithMaxHousePoint[0]);
          }

          if (housesWithMaxHouseName.length > 1) {
            setWinnerHouseArray(housesWithMaxHouseName);
          } else {
            setWinnerHouse(housesWithMaxHouseName[0]);
          }

          if (housesWithMaxParticipants.length > 1) {
            setPowerHouseArray(housesWithMaxParticipants);
          } else {
            setPowerHouse(housesWithMaxParticipants[0]);
          }
          console.log("hh", housesWithMaxHouseName.length, 'pp', housesWithMaxParticipants.length)
          const isOpen = sessionStorage.getItem("multiplewinnerModalOpen");
          if (housesWithMaxHouseName.length > 1 || housesWithMaxParticipants.length > 1) {
            console.log("jjmultiple")
            if (isOpen === null) {
              console.log("modal condition1", housesWithMaxHouseName, housesWithMaxParticipants, isOpen)
              setmultiplewinnerModalOpen(true);
              setTimeout(() => {
                setmultiplewinnerModalOpen(false);
              }, 5500);
            }
            sessionStorage.setItem("multiplewinnerModalOpen", false);
          }
          else if (housesWithMaxHouseName.length <= 1 || housesWithMaxParticipants.length <= 1) {
            console.log("jjsingle")
            setParticipants(response?.maximumParticipation?.participants);
            console.log("jjjres", response?.maximumPoints?.house);
            setWinnerPoints(response?.maximumPoints?.points);
            setWinnerHouse(response?.maximumPoints?.house);
            setPowerHouse(response?.maximumParticipation?.house);
            const isOpen = sessionStorage.getItem("winnerModalOpen");
            if (response?.maximumPoints?.house !== "null" && response?.maximumParticipation?.house !== "null") {
              if (isOpen === null) {
                setwinnerModalOpen(true);
                setTimeout(() => {
                  setwinnerModalOpen(false);
                }, 5500);
              }
              sessionStorage.setItem("winnerModalOpen", false);
            }
          }
        }
      });
    }
  }, [token, year]);
  console.log("modal condition2", winnerHouseArray, powerHouseArray)


  // useEffect(() => {
  //   if (token) {
  //     Api.get("/houses/titles").params({ queryYear: year }).send((response) => {

  //       setParticipants(response?.maximumParticipation?.participants)
  //       console.log("jjjres", participants)
  //       setWinnerPoints(response?.maximumPoints?.points)
  //       setWinnerHouse(response?.maximumPoints?.house);
  //       setPowerHouse(response?.maximumParticipation?.house);
  //       const isOpen = sessionstorage.getItem("winnerModalOpen");
  //       if (response?.maximumPoints?.house !== "null" && response?.maximumParticipation?.house !== "null") {
  //         if (isOpen === null) {
  //           setwinnerModalOpen(true);
  //           setTimeout(() => {
  //             setwinnerModalOpen(false);
  //           }, 5500);
  //         }
  //         sessionstorage.setItem("winnerModalOpen", false);
  //       }
  //     });
  //   }
  // }, [token, year]);

  // useEffect(() => {
  //   setWinnerHouseArray(["AGNI", "VAYU", "JAL"]);
  //   setPowerHouseArray(["AGNI", "VAYU", "JAL"]);
  // }, []);


  const onWhatshappenClick = (id) => {
    whatHappen.forEach((val) => {
      if (val.eventId === id) {
        setModalId(val);
      }
    });

    setOpen(true);
  };

  const [reload, setReload] = useState(false);
  const updateLike = (index) => {
    let tmp = whatHappen;
    tmp[index].liked = !tmp[index].liked;
    setWhatHappen(tmp);
    setModalId(tmp);
    setReload(!reload);
  };
  let sliderWidth = []
  sliderWidth = houseData?.filter((val) => val?.name === powerHouse)
  console.log("sliderWidth", sliderWidth)


  return (
    <>
      {houseData?.length === 0 ? (
        <>
          {width > 700 && <Row justify='center' style={{ marginTop: "100px" }}><Spin size='large' style={{ display: "flex", justifyContent: "center", marginTop: "30vh" }} /></Row>}
          {width < 700 && <Row justify='center' style={{ width: '100%', height: '100vh', }}><Spin size='large' style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} /></Row>}

        </>
      ) :
        (
          <div className="container">
            {
              !showAll && width > 700 && (
                <div className="Cont2" style={{ display: showAll ? "none" : "block" }}>
                  <div className="Title_container">
                    <div className="Title"> All Houses</div>
                    <div className="Yeardropdown">
                      <YearDropdown></YearDropdown>
                    </div>
                  </div>
                  <div className="Houses">
                    {houseData?.map((val) => {

                      let color, logo, hcolor, border;

                      if (val.name === "AGNI") {
                        color = "#ECB189";
                        logo = firelogo;
                        hcolor = "#CC5200";
                        border = "3px solid #CC5200"
                      } else if (val.name === "JAL") {
                        color = "#A3D6F0";
                        logo = waterlogo;
                        hcolor = "#348FBD";
                        border = "3px solid #348FBD"
                      } else if (val.name === "VAYU") {
                        color = "#F8EECB";
                        logo = windlogo;
                        hcolor = "#F0BC00";
                        border = "3px solid #F0BC00"
                      } else if (val.name === "AKASH") {
                        color = "#AA8FB5";
                        logo = spacelogo;
                        hcolor = "#5D3382";
                        border = "3px solid #5D3382"
                      } else if (val.name === "PRITHVI") {
                        color = "#CAE0D2";
                        logo = earthlogo;
                        hcolor = "#447A57";
                        border = "3px solid #447A57"
                      }

                      return (
                        <>
                          <Col className="houses-grid" span={4} style={{ backgroundColor: color, border: val.name === winnerHouse && border, }}>
                            {console.log("jjhouse", val?.name)}
                            {/* {val?.name == winnerHouse && (
                              <Lottie
                                options={defaultOptions}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  zIndex: "2",
                                  position: "absolute",
                                }}
                              />
                            )} */}
                            {winnerHouseArray.includes(val?.name) && (
                              <Lottie
                                options={defaultOptions}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  zIndex: "2",
                                  position: "absolute",
                                }}
                              />
                            )}
                            <img className="houses-logo"
                              src={logo}
                            ></img>
                            <div
                              className="houses-info"
                              style={{
                                paddingRight: val?.name === winnerHouse ? "0" : "15px",

                              }}
                            >
                              <h1 className="info-heading"
                                style={{
                                  color: hcolor,

                                }}
                              >
                                {val?.name}
                              </h1>
                              <div className="captain-info">
                                <div>
                                  <div className="captain-name">
                                    Captain:{" "}
                                    <Typography.Text className="captain-name"
                                      ellipsis={{ tooltip: true }}>
                                      {val?.captain}
                                    </Typography.Text>
                                  </div>
                                  <div className="team-size">
                                    Team: <b>{val?.team}</b>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginTop: "45px",
                                  width: "100%",
                                  marginLeft: "-10px",
                                  textAlign: "center",
                                }}
                              >
                                {/* {val?.name === winnerHouse && ( */}
                                {winnerHouseArray.includes(val?.name) && (
                                  <div
                                    style={{
                                      color: hcolor,
                                      fontWeight: "bold",
                                      fontSize: '18px',
                                      textSizeAdjust: 'auto'
                                    }}
                                  >
                                    Champions
                                  </div>
                                )}
                              </div>
                            </div>
                            <Col
                              style={{
                                width: "30%",
                                height: "auto",
                                position: "absolute",
                                top: "58%",
                                left: "70%",
                                // border: "2px solid green",
                              }}
                            >
                              {/* {val?.name === winnerHouse && ( */}
                              {winnerHouseArray.includes(val?.name) && (
                                <img
                                  src={winner}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    marginTop: "auto",
                                  }}
                                />
                              )}
                            </Col>
                          </Col>
                          <Col
                            style={{
                              width: "40px",
                              height: "auto",
                            }}
                          >
                            {/* {val?.name === powerHouse && ( */}
                            {powerHouseArray.includes(val?.name) && (
                              <div
                                style={{
                                  fontWeight: "bold",
                                  color: hcolor,
                                  transform: "rotate(90deg)",
                                  marginTop: "35px",
                                  fontSize: '18px',
                                  textSizeAdjust: 'auto'
                                }}
                              >
                                Power&nbsp;House
                              </div>
                            )}
                          </Col>
                        </>
                      );
                    })}

                  </div>
                </div>
              )
            }

            {
              width < 700 && (
                <>
                  <div className="Title_container">
                    <div className="Title"> All Houses</div>
                    <div className="Yeardropdown">
                      <YearDropdown></YearDropdown>
                    </div>
                  </div>
                  <div className="slider" style={{ display: "block", }}>
                    <Slider style={{ width: "95%", height: '100%', }}{...settings}>
                      {houseData?.map((val) => {

                        let color, logo, hcolor, border;

                        if (val.name === "AGNI") {
                          color = "#ECB189";
                          logo = firelogo;
                          hcolor = "#CC5200";
                          border = "3px solid #CC5200"
                        } else if (val.name === "JAL") {
                          color = "#A3D6F0";
                          logo = waterlogo;
                          hcolor = "#348FBD";
                          border = "3px solid #348FBD"
                        } else if (val.name === "VAYU") {
                          color = "#F8EECB";
                          logo = windlogo;
                          hcolor = "#F0BC00";
                          border = "3px solid #F0BC00"
                        } else if (val.name === "AKASH") {
                          color = "#AA8FB5";
                          logo = spacelogo;
                          hcolor = "#5D3382";
                          border = "3px solid #5D3382"
                        } else if (val.name === "PRITHVI") {
                          color = "#CAE0D2";
                          logo = earthlogo;
                          hcolor = "#447A57";
                          border = "3px solid #447A57"
                        }

                        return (
                          <div className="houses-grid-container" style={{ display: 'flex', overflow: 'visible' }}>
                            <div className="houses-grid" style={{ backgroundColor: color, border: val.name === winnerHouse ? border : '', width: '75vw', height: '30vh', position: 'relative', overflow: 'visible' }}>
                              <img className="houses-logo" src={logo} alt={val.name} />
                              {val?.name === winnerHouse && (
                                <Lottie options={defaultOptions} style={{ width: "100%", height: "100%", zIndex: "2", position: "absolute" }} />
                              )}
                              <div className="houses-info" style={{ paddingRight: val?.name === winnerHouse ? "0" : "15px" }}>
                                <h1 className="info-heading" style={{ color: hcolor, }}>{val?.name}</h1>
                                <Row className="captain-info">
                                  <Col>
                                    <div className="captain-name">
                                      Captain:{" "}
                                      <Typography.Text className="captain-name" ellipsis={{ tooltip: true }}>{val?.captain}</Typography.Text>
                                    </div>
                                    <div className="team-size">
                                      Team: <b>{val?.team}</b>
                                    </div>
                                  </Col>
                                  <Col className="winnerCup">
                                    {val?.name === winnerHouse && (
                                      <img src={winner} style={{ width: "100%", height: "100%" }} />
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            {val?.name === powerHouse && (
                              <div className="PowerHseCol" style={{ fontWeight: "bold", color: hcolor, transform: "rotate(90deg)", fontSize: '18px', textSizeAdjust: 'auto', position: 'relative', left: '127px', bottom: '16px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                Power&nbsp;House
                              </div>
                            )
                            }
                            {val?.name === winnerHouse && (
                              <div className="ChampionsCol" style={{ fontWeight: "bold", color: hcolor, fontSize: '18px', textSizeAdjust: 'auto', position: 'relative', bottom: '32px', right: '17px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                Champions
                              </div>
                            )}
                          </div>
                        );

                      })}
                    </Slider>
                  </div>
                </>
              )
            }


            <Row style={{ marginTop: "40px", columnGap: "1vw", }} className="main_row">
              {width > 700 &&
                <>
                  <Col className="Happeningcol">
                    <div className="whats-happening-title">
                      What's happening
                    </div>
                    <div className="whats-happening-container">
                      <Row className="whats-happening-content" >
                        {whatHappen.map((val, index) => {
                          const date = getDate(val.createdAt);

                          return (
                            <Col className="events-list" id={val.eventId} key={index}  >
                              <Row className="events-head">
                                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '95%', padding: '5px 0px 0px 10px', font: 'poppins', fontSize: '18px', fontWeight: '600' }}>
                                  {val?.title}
                                </Typography.Text>
                                <div className="events-profile">
                                  <div className="profile-name">{val.createdBy}</div>
                                  <img className="profile-image" src={PublisherIcon}></img>
                                </div>
                              </Row>
                              <div style={{ cursor: 'pointer' }} className="events-image-container">
                                <SliderImage
                                  media={val?.media}
                                  onWhatshappenClick={onWhatshappenClick}
                                  id={val.eventId}
                                />
                              </div>
                              <Row className="events-img-footer">
                                <img style={{ cursor: 'pointer' }} width="24px" height="24px" data={val.eventId} src={val && val?.liked ? redhearticon : hearticon} onClick={() => {
                                  updateLike(index);
                                  dispatch(overViewActions.likesList(!likes));
                                  return new Promise((resolve, reject) => {
                                    Api.post("/ibelong-posts/like").params({ postId: val?.eventPostId }).send((response, error) => {
                                      console.log("111jjj", response, "error", error)
                                      resolve();
                                      if (response) {
                                        console.log("111response", response)
                                      }
                                    });
                                  });
                                }}></img>
                                <p className="events-img-date">
                                  {moment(val?.createdAt).format("lll")}
                                </p>
                              </Row>
                            </Col>

                          );
                        })}
                        <OverviewModal data={modalId} onClicked={setIconClicked} setOpen={setOpen} open={open} />
                      </Row>
                    </div>
                  </Col>
                  {
                    !showAll && (
                      <div style={{ height: '89vh', width: '29%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Col style={{ height: '20%', }} >
                          <div style={{ font: 'normal normal bold 24px / 35px Poppins' }}>House Scores</div>
                          <div style={{ display: 'flex', flexDirection: 'column', }}>
                            <Slider className="Sliderpoints" style={{ marginLeft: '15px', width: "90%", height: '100%' }} {...settings}>
                              {console.log("points", AllhousePoints)}

                              {sortedHouses.map((house, index) => {
                              
                                if (house.points !== previousPoints) {
                                  currentRankShowAll = currentRankShowAll + 1;
                                }
                                previousPoints = house.points;

                                return (
                                  <div key={house.id} style={{}}>
                                    {console.log("houseid", house?.id, house.name, 'user', house[0]?.user?.id)}
                                    <div style={{ padding: '10px', width: '100%', height: '13vh', border: '1px solid #CCCCCC', borderRadius: '12px', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: house.name === 'AGNI' ? '#FDF1E9' : house.name === 'JAL' ? '#EAF4F8' : house.name === 'VAYU' ? '#FEFBEF' : house.name === 'AKASH' ? '#EEEAF2' : house.name === 'PRITHVI' ? '#F0F9F3' : 'black' }}>
                                      {(AllhousePoints[0]?.points == 0) ? '' : <div style={{ width: '6vw', }}>
                                        {currentRankShowAll === 1 && <img style={{ width: '75px', height: '75px' }} src={Rank1} />}
                                        {currentRankShowAll === 2 && <img style={{ width: '75px', height: '75px' }} src={Rank2} />}
                                        {currentRankShowAll === 3 && <img style={{ width: '75px', height: '75px' }} src={Rank3} />}
                                        {currentRankShowAll > 3 && <div style={{ width: '75px', justifyContent: 'center', fontSize: '18px', fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>{5}<div style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'auto' }}>th</div></div>}
                                      </div>}
                                      <div style={{ marginRight: '10px', }}>
                                        {house.name === 'AGNI' && <img style={{ width: '45px', height: '45px' }} src={firelogo} />}
                                        {house.name === 'JAL' && <img style={{ width: '45px', height: '45px' }} src={waterlogo} />}
                                        {house.name === 'VAYU' && <img style={{ width: '45px', height: '45px' }} src={windlogo} />}
                                        {house.name === 'AKASH' && <img style={{ width: '45px', height: '45px' }} src={spacelogo} />}
                                        {house.name === 'PRITHVI' && <img style={{ width: '45px', height: '45px' }} src={earthlogo} />}
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: '5px' }}>
                                        <div style={{ font: 'normal normal bold 18px/20px Open Sans', color: house.name === 'AGNI' ? '#EC782A' : house.name === 'JAL' ? '#348FBD' : house.name === 'VAYU' ? '#F8CE37' : house.name === 'AKASH' ? '#5D3382' : house.name === 'PRITHVI' ? '#447A57' : 'black' }}>{house.name}<div style={{ color: '#999999', fontSize: '12px', fontWeight: '400' }}>House</div></div>
                                        <div style={{ font: 'normal normal bold 18px/20px  Open Sans', color: house.name === 'AGNI' ? '#EC782A' : house.name === 'JAL' ? '#348FBD' : house.name === 'VAYU' ? '#F8CE37' : house.name === 'AKASH' ? '#5D3382' : house.name === 'PRITHVI' ? '#447A57' : 'black' }}>{house.points}<div style={{ color: '#999999', fontSize: '12px', fontWeight: '400' }}>Score</div></div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                          <div style={{ fontWeight: '600', marginTop: '20px', display: 'flex', justifyContent: 'flex-end', width: '100%', cursor: 'pointer' }} onClick={handleViewAllClick}>
                            View all
                          </div>
                          {showAllData && (
                            <div className="custom-modal-container" style={{ padding: '0px 20px 0px 20px', width: '50%', height: '80vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: '#F3F6FA', display: 'flex', boxShadow: 'rgba(22, 23, 23, 0.77) 5px 5px 57px 60vw', borderRadius: '4px' }}>
                              <div className="modal-content" style={{ width: '100%', }}>
                                <div style={{ width: '95%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', marginLeft: '25px', marginBottom: '10px' }}>
                                  <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '700', }}>House Scores</div>
                                  <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={toggleModal} />
                                </div>
                                <div style={{ overflow: 'auto', maxHeight: 'calc(80vh - 2 * 20px - 45px)' }}>
                                  {sortedHouses.map((house, index) => {
                                    if (house.points !== previousPoints) {
                                      currentRank = currentRank + 1;
                                    }
                                    previousPoints = house.points;

                                    return (
                                      <div key={house.id} style={{ margin: '25px' }}>
                                        <div style={{ padding: '25px', width: '100%', height: '15vh', border: '1px solid #CCCCCC', borderRadius: '12px', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: house.name === 'AGNI' ? '#FDF1E9' : house.name === 'JAL' ? '#EAF4F8' : house.name === 'VAYU' ? '#FEFBEF' : house.name === 'AKASH' ? '#EEEAF2' : house.name === 'PRITHVI' ? '#F0F9F3' : 'black' }}>
                                          {(AllhousePoints[0]?.points == 0) ? '' : <div style={{ width: '10vw', }}>
                                            {currentRank === 1 && <img style={{ width: '75px', height: '75px' }} src={Rank1} />}
                                            {currentRank === 2 && <img style={{ width: '75px', height: '75px' }} src={Rank2} />}
                                            {currentRank === 3 && <img style={{ width: '75px', height: '75px' }} src={Rank3} />}
                                            {currentRank > 3 && <div style={{ width: '75px', justifyContent: 'center', fontSize: '18px', fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>{5}<div style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'auto' }}>th</div></div>}
                                          </div>}
                                          <div style={{ marginRight: '15px', marginLeft: '10px' }}>
                                            {house.name === 'AGNI' && <img style={{ width: '45px', height: '45px' }} src={firelogo} />}
                                            {house.name === 'JAL' && <img style={{ width: '45px', height: '45px' }} src={waterlogo} />}
                                            {house.name === 'VAYU' && <img style={{ width: '45px', height: '45px' }} src={windlogo} />}
                                            {house.name === 'AKASH' && <img style={{ width: '45px', height: '45px' }} src={spacelogo} />}
                                            {house.name === 'PRITHVI' && <img style={{ width: '45px', height: '45px' }} src={earthlogo} />}
                                          </div>
                                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '90%', padding: '10px' }}>
                                            <div style={{ font: 'normal normal bold 18px/20px Open Sans', color: house.name === 'AGNI' ? '#EC782A' : house.name === 'JAL' ? '#348FBD' : house.name === 'VAYU' ? '#F8CE37' : house.name === 'AKASH' ? '#5D3382' : house.name === 'PRITHVI' ? '#447A57' : 'black' }}>{house.name}<div style={{ color: '#999999', fontSize: '12px', fontWeight: '400' }}>House</div></div>
                                            <div style={{ font: 'normal normal bold 18px/20px  Open Sans', color: house.name === 'AGNI' ? '#EC782A' : house.name === 'JAL' ? '#348FBD' : house.name === 'VAYU' ? '#F8CE37' : house.name === 'AKASH' ? '#5D3382' : house.name === 'PRITHVI' ? '#447A57' : 'black' }}>{house.points}<div style={{ color: '#999999', fontSize: '12px', fontWeight: '400' }}>Score</div></div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          )}

                        </Col>
                        <Col className="EventsCol" style={{}} >
                          <div className="Event-title">
                            Events
                          </div>
                          <div className="Event-container">
                            <div className="Event-content">
                              {futureEvent.map((data, index) => {
                                const date1 = data.startDate.slice(8, 10);
                                const date2 = months[parseInt(data.startDate.slice(5, 7))];
                                return (
                                  <>
                                    <div className="Event-details" key={index} onClick={() => {
                                      history.push({ pathname: "/event-details", state: { data }, });
                                    }}>
                                      <div style={{ boxSizing: 'border-box', width: '100px', fontSize: '1.1vw', background: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <div className="Event-date">
                                          {date1}
                                          {date1 == 1 ? "st" : date1 == 2 ? "nd" : date1 == 3 ? "rd" : date1 == 21 ? "st" : date1 == 22 ? "nd" : date1 == 23 ? "rd" : date1 == 31 ? "st" : "th"}

                                        </div>
                                        <div className="Event-month">
                                          {date2}
                                        </div>
                                      </div>
                                      <div className="Event-name-container">
                                        <div style={{ height: '40%' }}>
                                          <Typography.Text className="Event-name" ellipsis={{ tooltip: true }} >
                                            {data.title}
                                          </Typography.Text>
                                        </div>
                                        <div style={{ height: '60%', width: '100%' }}>
                                          <Typography.Paragraph style={{ maxHeight: '100%', width: '90%' }} className="Event-info" ellipsis={{ tooltip: true, rows: 2 }}>
                                            {data.description}
                                          </Typography.Paragraph>
                                        </div>
                                      </div>
                                    </div >

                                  </>
                                );
                              })}
                            </div >
                          </div>

                        </Col>
                      </div>
                    )
                  }
                </>
              }

              {
                width < 700 &&
                <>
                  <Col className="HappeningcolMobile">
                    <div className="Title">What's happening</div>
                    <Row className="whats-happening-content">
                      {whatHappen.slice(0, displayedCount).map((val, index) => {
                        const date = getDate(val.createdAt);
                        return (
                          <Col className="events-list" id={val.eventId} key={index}>
                            <Row className="events-head">
                              <Typography.Text className="events-name" ellipsis={{ tooltip: true }}>
                                {val?.title}
                              </Typography.Text>
                              <div className="events-profile">
                                <div className="profile-name">{val.createdBy}</div>
                                <img className="profile-image" src={PublisherIcon}></img>
                              </div>
                            </Row>
                            <div className={classes.events_image_container}>
                              <div className="events-img-footer">
                                <img style={{ width: '24px', height: '24px' }}
                                  data={val.eventId}
                                  src={val && val?.liked ? redhearticon : heartImage}
                                  onClick={() => {
                                    updateLike(index);
                                    dispatch(overViewActions.likesList(!likes));
                                    return new Promise((resolve, reject) => {
                                      Api.post("/ibelong-posts/like")
                                        .params({ postId: val?.eventPostId })
                                        .send((response, error) => {
                                          resolve();
                                          if (response) {
                                          }
                                        });
                                    });
                                  }}
                                ></img>
                                <p className="events-img-date">{moment(val?.createdAt).format("lll")}</p>
                              </div>
                              <div>
                                <SliderImage media={val?.media} onWhatshappenClick={onWhatshappenClick} id={val.eventId} />
                              </div>

                            </div>
                          </Col>
                        );
                      })}
                      <OverviewModal data={modalId} onClicked={setIconClicked} setOpen={setOpen} open={open} />
                      <div className="View-more-container">
                        {whatHappen.length > 2 && (
                          <div className="View-more" onClick={toggleShowAll}>
                            {showAll ? "View less" : "View more"}
                            <img className="arrowImg" style={{ width: '14px', marginLeft: '4px' }} src={arrowRight} />
                          </div>
                        )}
                      </div>
                    </Row>
                  </Col>

                  <Col style={{ marginBottom: '12px' }} >
                    <div style={{ font: 'normal normal bold 18px / 28px Poppins' }}>House Scores</div>
                    <div style={{ display: 'flex', flexDirection: 'column', }}>
                      <Slider style={{ marginLeft: '15px', width: "90%", height: '100%', padding: '5px' }} {...settings}>
                        {/* {AllhousePoints.sort((a, b) => b.points - a.points).map((house, index) => ( */}
                        {sortedHouses.map((house, index) => {
                          if (house.points !== previousPoints) {
                            currentRank = currentRank + 1;
                          }

                          previousPoints = house.points;
                          return (
                            <div key={house.id}  >
                              <div style={{ padding: '5px', height: '13vh', border: '1px solid #CCCCCC', borderRadius: '12px', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: house.name === 'AGNI' ? '#FDF1E9' : house.name === 'JAL' ? '#EAF4F8' : house.name === 'VAYU' ? '#FEFBEF' : house.name === 'AKASH' ? '#EEEAF2' : house.name === 'PRITHVI' ? '#F0F9F3' : 'black', }}>
                                {(AllhousePoints[0]?.points == 0) ? '' : <div style={{ width: '20%', paddingTop: '9px', paddingBottom: '9px', marginRight: '10px' }}>
                                  {currentRank === 1 && <img style={{ width: '70px', height: '70px' }} src={Rank1} />}
                                  {currentRank === 2 && <img style={{ width: '70px', height: '70px' }} src={Rank2} />}
                                  {currentRank === 3 && <img style={{ width: '70px', height: '70px' }} src={Rank3} />}
                                  {currentRank > 3 && <div style={{ width: '70px', justifyContent: 'center', fontSize: '16px', fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>{currentRank}<div style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'auto' }}>th</div></div>}
                                </div>}
                                <div style={{ marginRight: '8px', marginLeft: '8px', }}>
                                  {house.name === 'AGNI' && <img style={{ width: '40px', height: '40px' }} src={firelogo} />}
                                  {house.name === 'JAL' && <img style={{ width: '40px', height: '40px' }} src={waterlogo} />}
                                  {house.name === 'VAYU' && <img style={{ width: '40px', height: '40px' }} src={windlogo} />}
                                  {house.name === 'AKASH' && <img style={{ width: '40px', height: '40px' }} src={spacelogo} />}
                                  {house.name === 'PRITHVI' && <img style={{ width: '40px', height: '40px' }} src={earthlogo} />}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '70%', paddingRight: '5px' }}>
                                  <div style={{ font: 'normal normal bold 16px/20px Open Sans', color: house.name === 'AGNI' ? '#EC782A' : house.name === 'JAL' ? '#348FBD' : house.name === 'VAYU' ? '#F8CE37' : house.name === 'AKASH' ? '#5D3382' : house.name === 'PRITHVI' ? '#447A57' : 'black', }}>{house.name}<div style={{ color: '#999999', fontSize: '12px', fontWeight: '500' }}>House</div></div>
                                  <div style={{ font: 'normal normal bold 16px/20px  Open Sans', color: house.name === 'AGNI' ? '#EC782A' : house.name === 'JAL' ? '#348FBD' : house.name === 'VAYU' ? '#F8CE37' : house.name === 'AKASH' ? '#5D3382' : house.name === 'PRITHVI' ? '#447A57' : 'black', }}>{house.points}<div style={{ color: '#999999', fontSize: '12px', fontWeight: '500' }}>Score</div></div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                        {/* ))} */}
                      </Slider>
                    </div>
                  </Col>

                  <Col className="EventsCol" >
                    <div className="Title">
                      Events
                    </div>
                    <div className="Event-container">
                      <div
                        className="Event-content">
                        {futureEvent.map((data, index) => {
                          const date1 = data.startDate.slice(8, 10);
                          const date2 = months[parseInt(data.startDate.slice(5, 7))];
                          return (
                            <>
                              <div className="Event-details" key={index} onClick={() => {
                                history.push({ pathname: "/event-details", state: { data }, });
                              }}>
                                <div style={{ boxSizing: 'border-box', width: '100px', fontSize: '1.1vw', background: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                  <div className="Event-date">
                                    <b>
                                      {date1}
                                      {date1 == 1 ? "st" : date1 == 2 ? "nd" : date1 == 3 ? "rd" : date1 == 21 ? "st" : date1 == 22 ? "nd" : date1 == 23 ? "rd" : date1 == 31 ? "st" : "th"}
                                    </b>
                                  </div>
                                  <div className="Event-month">
                                    {date2}
                                  </div>
                                </div>
                                <div className="Event-name-container">
                                  <div style={{ height: '40%' }}>
                                    <Typography.Text className="Event-name" ellipsis={{ tooltip: true }} style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'poppins' }}>
                                      {data.title}
                                    </Typography.Text>
                                  </div>
                                  <div style={{ height: '60%', width: '100%' }}>
                                    <Typography.Paragraph style={{ maxHeight: '100%', width: '90%' }} className="Event-info" ellipsis={{ tooltip: true, rows: 2 }}>
                                      {data.description}
                                    </Typography.Paragraph>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>

                  </Col>
                  {/* )
                 } */}
                </>
              }


            </Row >

            <Modal closable={true} className="winner" bodyStyle={{ background: "linear-gradient(5deg, #111111, rgb(0 0 0 / 56%)) ", backdropFilter: "blur(10px)", }} footer={null}
              centered
              open={winnerModalOpen} handlemodalcancel={() => setIsModalOpen(true)} onCancel={() => setwinnerModalOpen(false)} >
              <WinnerModal winnerHouse={winnerHouse} powerHouse={powerHouse} year={year} setwinnerModalOpen={setwinnerModalOpen} />
            </Modal>

            <Modal closable={false} bodyStyle={{ justifyContent: 'center', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90vw', height: '80vh', background: "linear-gradient(5deg, #111111, rgb(0 0 0 / 56%)) ", backdropFilter: "blur(10px)", }} footer={null}
              centered
              open={multiplewinnerModalOpen} handlemodalcancel={() => setIsModalOpen(true)} onCancel={() => setmultiplewinnerModalOpen(false)} >
              <MultipleWinnerModal year={year} setmultiplewinnerModalOpen={setmultiplewinnerModalOpen} powerHouseArray={powerHouseArray} winnerHouseArray={winnerHouseArray} />
            </Modal>
          </div >
        )
      }
    </>
  );
}

export default Overview;