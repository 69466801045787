
import classes from './MainHeader.module.css';
import logo from '../../images/iBelongLogo.svg';
import Notification from '../../images/notification.png';
import userIcon from "../../images/profileIcon.jpg"
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'component/Pages/store';
import { Button, Row, Col } from 'antd';
import { useState, useEffect } from 'react';
import credentials from "../../credentials";
import backLogo from "../../images/backLogo.png";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
const MainHeader = (props) => {
  const { height, width } = useWindowDimensions();
  const { userImage } = useSelector(state => state.auth)
  const [enable, setEnable] = useState(false);
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  const closeTab = () => {
    dispatch(authActions.removeUser());
    dispatch(authActions.updateToken({token:null}))
    let platform=credentials?.backendUrl;
    if(platform==="https://loginv2.brigosha.com"){
      // window.location.replace("https://newpmt.brigosha.com/homePage")
    return;
    }
    window.location.replace("https://login.brigosha.com/homePage")
  };

  return (
    <>
      {
        width > 700 &&
        <header style={{ zindex: '2' }} className={classes.header}>
          <div style={{display:"flex",flexDirection:"row",gap:"rem",alignItems:"center",marginLeft:"1.6rem",justifyContent:""}}>
           <div onClick={closeTab} >
          <img src={backLogo} alt="back" style={{height:"2rem",width:"rem",cursor:"pointer"}} />
          </div>
          <div className={classes.logo} style={{marginLeft:"0.9rem"}}>
            <img src={logo} alt="ibelong logo"></img>
          </div>
          </div>
          <div className={classes.user} 
          // style={{ cursor: 'pointer' }}
          >
            <img src={userImage !== null ? userImage : userIcon} alt="userImage" ></img>
            <span onClick={() => setEnable(true)}>{props.user}</span>
            {/* <Row>
              <Col style={{ width: '100%' }}>
                {enable ? <Button onClick={closeTab}>Logout</Button> : ""}
              </Col>
            </Row> */}
          </div>
        </header>}

      {
        width < 700 &&
        <header style={{ marginTop: '10px', position: 'fixed', width: '100vw', height: '5vh', display: 'flex', alignItems: 'center', backgroundColor: ' #ffffff', justifyContent: 'flex-end', }} className={classes.header}>
          <img src={userImage !== null ? userImage : userIcon} alt="userImage" onClick={() => setEnable(true)} style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '5px', }} />
          {enable ? <Button style={{ marginRight: '10px' }} onClick={closeTab}>Logout</Button> : ""}
        </header>
      }
    </>


  );
};

export default MainHeader;
