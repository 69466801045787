import classes from "./eventDetails.module.css";
import React, { useState, useContext } from "react";
import {
  Switch,
  Modal,
  Row,
  Col,
  Card,
  Select,
  Radio,
  Typography,
  Input,
  message,
  Form,
  DatePicker,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import PdfLogo from "../../../images/pdf-logo.svg";
import AgniCard from "../../Pages/houses/img/agni.png";
import PrithviCard from "../../Pages/houses/img/prithvi.png";
import JalCard from "../../Pages/houses/img/jal.png";
import AkashCard from "../../Pages/houses/img/akash.png";
import VayuCard from "../../Pages/houses/img/vayu.png";
import EditButton from "../../../images/edit icon.svg";
import "./eventdetails.css";
import pdfLogo from "../../../images/pdf-logo.svg";
import "antd/dist/antd.css";
import { Table, Button, Space } from "antd";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import backLogo from "../../../images/backLogo.png";
import UserProfileDetails from "../userProfileDetails/userProfileDetails";
import { useLocation, useHistory } from "react-router-dom";
import Api from "services/Api";
import ScrollMenu from "react-horizontal-scroll-menu";
import { Upload, Tabs } from "antd";
import "./events.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import VideoModal from "./videomodal";
import ImageModal from "./imageModal";
import GroupModal from "./groupModal";
// import {  tableParticipant } from "./columndataEvent";
//import classes from './eventDetails.module.css';
import x from '../../../images/x.svg';
import jwt_decode from "jwt-decode";
import {
  DownloadOutlined,
  UndoOutlined,
  EyeOutlined,
  PlusOutlined,
  LoadingOutlined,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import UploadPdfDocs from "./uploadpdfDocs";
import UploadbyUserImg from "./uploadbyuserimg";
import UploadAnyFile from "./UploadAnyFile";
import PlayVideo from "./playVideo";
import store from "store";
import { houseActions } from "../houses/store";
import ReactPlayer from "react-player";
import ViewGroupModal from "./viewGroupModal";
import SelectWinnerModal from "./selectWinnerModal";
import AssociateFiles from "./viewAssocUploadefiles";
import GroupFiles from "./GroupUpload";
import profileIcon from "../../../images/profileIcon.jpg";
import SearchCompo from "component/searchCompo";
import moment from "moment";
import { authActions } from "../store";
import { file } from "@babel/types";
import Events from "./Events";
import Notification from "services/Notification";
import { useWindowDimensions } from "../../windowDimention/windowDimension";
import EditGroupParticipants from "./editGroupParticipants";
//import { set } from "immer/dist/internal";
// import { CSVLink } from "react-csv";
// import { Group } from "antd/lib/avatar";
//import download from "downloadjs";
// import fileDownload from 'js-file-download'
const { Option } = Select;
const { Title } = Typography;
const { TabPane } = Tabs;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const EventFilesMob = ({ setGroupDetails, groupDetails, record, details, user, optional, setVideoModal, setOptional, setAddMedia, setUploaderId, Tab }) => {
  let disableCondition = false;
  if (details?.fileType === "none" || details?.fileType === null) {
    return <p>N/A</p>;
  }
  if (record?.media !== null && record?.media?.length !== 0) {
    return (
      <Row>
        <a
          style={{ textDecoration: "underline", }}
          onClick={() => {
            console.log("MediaRecord", record?.media);
            setVideoModal(true);
            setAddMedia(record?.media);
            setUploaderId(record?.id);
            setGroupDetails(record)
            console.log("sssrec", record?.id)


          }}
        >
        {(user?.id !== record?.id && user?.role !== "SuperAdmin")?'view files':'upload additional files'}
        </a>
        {record?.media?.length === 5 && record?.stage === "Shortlisted" && (
          <Button
            style={{ marginLeft: "15px", marginTop: "-10px" }}
            onClick={() => setOptional(true)}
          >
            <UndoOutlined />
          </Button>
        )}
      </Row>
    );
  }
  return (
    <>
      {user?.id !== record?.id &&
        user?.role !== "SuperAdmin"  ? (
        <div style={{ paddingLeft: "10px" }}>N/A</div>
      ) : (
        <Button
          disabled={
            user?.role !== "SuperAdmin" &&
            !details?.nominationStatus
          }
          className={classes.colItem}
          onClick={() => {
            setAddMedia();
            setVideoModal(true);
            setUploaderId(record?.id);
            console.log("sssrec", record?.id)

          }}
        >
          Upload{" "}
          {details?.fileType === "application"
            ? "Pdf"
            : details?.fileType}
        </Button>
      )}
    </>
  );

}

export const EventStageReached = ({ record, user, details, getApiWithSearch }) => {
  let disabled = true;
  if (record?.stage === "Nominee") {
    if (user?.role === "Captain" || user?.role === "ViceCaptain") {
      disabled =
        details?.fileType === "none"
          ? false
          : record?.media === null
            ? true
            : false;
    }
  } else if (record?.stage === "Shortlisted") {
    if (
      user?.privilege === "Admin" ||
      user?.role === "SuperAdmin" ||
      user?.privilege === "EventOrganisers"
    ) {
      disabled = false;
    }
  }
  return (
    <Select
      disabled={disabled}
      value={record?.stage}
      onChange={(e) => {
        if (e === "Shortlisted" || e === "Finalists") {
          Api.post("/ibelong-events/participate")
            .params({
              eventId: details?.id,
              participantId: record?.id,
              stage: e,
            })
            .send((response) => {
              getApiWithSearch();
            });
        }
      }}
    >
      {record?.stage === "Nominee" ? (
        <>
          <Option disabled={true} value="Nominee">
            Nominee
          </Option>
          <Option value="Shortlisted">Shortlisted</Option>
        </>
      ) : record?.stage === "Shortlisted" ? (
        <>
          <Option disabled={true} value="Shortlisted">
            Shortlisted
          </Option>
          <Option value="Finalists">Finalists</Option>
        </>
      ) : (
        <Option>{record?.stage}</Option>
      )}
    </Select>
  );

}

export const IndividualMobData = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  console.log("showDetails", props?.item);
  return (
    <div>
      <div
        className={classes.UserData}
        onClick={() => setShowDetails((prev) => !prev)}
      >
        <div>
          <img
            src={
              props?.item?.profileImage
                ? props?.item?.profileImage
                : profileIcon
            }
          />
        </div>

        <span>{props?.item?.name}</span>
        <div>
          {!showDetails && (
            <RightOutlined
              style={{ fontWeight: "bold", fontSize: "18px", color: "grey" }}
            />
          )}
          {showDetails && (
            <DownOutlined
              style={{ fontWeight: "bold", fontSize: "18px", color: "grey" }}
            />
          )}
        </div>
      </div>
      {showDetails && (
        <div className={classes.restDetails}>
          <div>
            <span>Employee Id</span>
            <span>{props?.item?.uid}</span>
          </div>
          <div>
            <span>Contact Details</span>
            <div className={classes.contacts}>
              <span>{props?.item?.phone}</span>
              <span>
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{ width: "9rem", color: "gray", fontSize: "13px" }}
                >
                  {props?.item?.email}
                </Typography.Text>
              </span>
            </div>
          </div>
          <div>
            <span>Department</span>
            <span>{props?.item?.department ? props?.item?.department : "NA"}</span>
          </div>

          <div>
            <span>House</span>
            <span>{props?.item?.houseName}</span>
          </div>

          <div>
            <span> Event Stage Reached</span>
            <EventStageReached record={props?.item} user={props?.user} details={props?.details} getApi={props?.getApi} getApiWithSearch={props?.getApiWithSearch}/>
          </div>

          <div>
            <span>Files</span>
            <EventFilesMob setGroupDetails={props?.setGroupDetails} record={props?.item} details={props?.details} user={props?.user} optional={props?.optional} setVideoModal={props?.setVideoModal} setOptional={props?.setOptional} setUploaderId={props?.setUploaderId} setAddMedia={props?.setAddMedia} />
          </div>

          <div>
            <NavLink
              to={{
                pathname: "/userProfile",
                state: { data: props?.item },
              }}
            >
              <a>View more</a>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export const GroupMob = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  console.log("GroupshowDetails", props?.item);
  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingBottom: "1rem", fontWeight: "600", borderBottom: "1.3px solid rgb(232, 224, 224)", marginBottom: "1rem" }}
        onClick={() => setShowDetails((prev) => !prev)}
      >

        <span>
          <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "7rem" }} >  {props?.item?.groupName}</Typography.Text>
        </span>
        <div>
          {!showDetails && (
            <RightOutlined
              style={{ fontWeight: "bold", fontSize: "18px", color: "grey" }}
            />
          )}
          {showDetails && (
            <DownOutlined
              style={{ fontWeight: "bold", fontSize: "18px", color: "grey" }}
            />
          )}
        </div>
      </div>
      {showDetails && (
        <div className={classes.restDetails} style={{ marginBottom: "1rem" }}>

          <div>
            <span>Contact Details</span>
            <div className={classes.contacts}>
              <span>{props?.item?.createdBy?.phone}</span>
              <span>
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{ width: "9rem", color: "gray", fontSize: "13px" }}
                >
                  {props?.item?.createdBy?.email}
                </Typography.Text>
              </span>
            </div>
          </div>
          <div>
            <span>Group Stage</span>
            <GroupStage record={props?.item} user={props?.user} getGroups={props?.getGroups} details={props?.details} />
          </div>

          <div>
            <span>Files</span>
            <GroupFileMob refreshTst='tst' setGroupDetails={props?.setGroupDetails} record={props?.item}  groupRole={props?.groupRole} details={props?.details} user={props?.user} optional={props?.optional} setVideoModal={props?.setVideoModal} setOptional={props?.setOptional} setUploaderId={props?.setUploaderId} setAddMedia={props?.setAddMedia} />
          </div>

          <div>
            <span>Team List</span>
            <div
              onClick={() => {
                props?.setGroupOpenmodal(true);
                props?.setId(props?.item?.id);
              }}
              style={{
                display: "flex",
                // gap: "5px",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <EyeOutlined></EyeOutlined>
              <div>View Group List1</div>
            </div>
          </div>


        </div>
      )}
    </div>
  );
};

export const GroupFileMob = ({ setGroupDetails, groupDetails, record, details, user, optional, setVideoModal, setOptional, setAddMedia, setUploaderId, Tab,groupRole }) => {
  if (details?.fileType === "none" || details?.fileType === null) {
    return <p>N/A</p>;
  }
  // if (
  //   record?.groupStage === "Shortlisted" &&
  //   record?.posts?.length === 1 &&
  //   optional === true
  // ) {
  //   return (
  //     <>
  //       <Space>
  //         <Button
  //           // disabled={ user?.id !== record?.createdBy?.id?(user?.id !== record?.createdBy):(!details?.nominationStatus)}
  //           disabled={
  //             details?.nominationStatus
  //               ? user?.privilege === "EventCoordinators" ||
  //                 user?.id === record?.createdBy?.id
  //                 ? false
  //                 : true
  //               : true
  //           }
  //           className={classes.colItem}
  //           onClick={() => {
  //             store.dispatch(houseActions.openVideoModal(true));
  //             store.dispatch(houseActions?.groupDetails(record));
  //           }}
  //         >
  //           Upload{" "}
  //           {details?.fileType === "application"
  //             ? "Pdf"
  //             : details?.fileType}
  //         </Button>
  //         <Button onClick={() => setOptional(false)}>X</Button>
  //       </Space>
  //     </>
  //   );
  // }
  if (record?.posts !== null && record?.posts?.length !== 0) {
    return (
      <div>
        <a
          style={{ textDecoration: "underline" }}
          onClick={() => {
            // setgroupUpload(true);
            setVideoModal(true);
            store.dispatch(houseActions.openVideoModal(true));
            // setGroupsMedia({fileType: record?.event?.fileType,record: record,});
            setAddMedia(record?.posts);
            store.dispatch(houseActions?.groupDetails(record));
            setUploaderId(record?.createdBy?.id);
            setGroupDetails(record);
          }}
        >
      {(user?.privilege === "EventCoordinators" || user?.role === "SuperAdmin" || groupRole === "Group Leader" ||user?.id === record?.createdBy?.id)?'Upload additional files':'View files'} 
        </a>
        {/* {record?.posts?.length === 1 && record?.groupStage === "Shortlisted" && (
            <Button style={{ marginLeft: "10px", marginTop: "-5px" }} onClick={() => setOptional(true)}>
              <UndoOutlined />
            </Button>
          )} */}
      </div>
    );
  }
  return (
    <>
        <Button
          //  disabled={record?.createdBy?.id !== user?.id?(user?.id !== record?.createdBy):(!details?.nominationStatus)}
          disabled={
            details?.nominationStatus
                    ? ((user?.privilege === "EventCoordinators" || user?.role === "SuperAdmin" || groupRole === "Group Leader" ||user?.id === record?.createdBy?.id)
                      ? false
                      : true)
                    : user?.role !== "SuperAdmin"
          }
          className={classes.colItem}
          onClick={() => {
            store.dispatch(houseActions.openVideoModal(true));
            store.dispatch(houseActions?.groupDetails(record));
            setUploaderId(record?.createdBy?.id);
          }}
        >
          Upload{" "}
          {record?.event?.fileType === "application"
            ? "Pdf"
            : record?.event?.fileType}
        </Button>
    </>
  );
}

export const GroupStage = ({ record, user, getGroups, details }) => {
  let disabled = true;
  if (record?.groupStage === "Nominee") {
    if (user?.role === "Captain" || user?.role === "ViceCaptain" || user?.role === 'SuperAdmin') {
      disabled =
        details?.fileType === "none"
          ? false
          : record?.posts?.length === 0
            ? true
            : false;
    }
  } else if (record?.groupStage === "Shortlisted") {
    if (
      user?.privilege === "Admin" ||
      user?.role === "SuperAdmin" ||
      user?.privilege === "EventOrganisers"
    ) {
      disabled = false;
    }
  }
  return (
    <Select
      disabled={disabled}
      value={record?.groupStage}
      onChange={(e) => {
        if (e === "Shortlisted" || e === "Finalists") {
          Api.patch("/ibelong-events/groupStatus")
            .params({ groupId: record?.id, stage: e })
            .send((response) => {
              getGroups();
            });
        }
      }}
    >
      {record?.groupStage === "Nominee" ? (
        <>
          <Option disabled={true} value="Nominee">
            Nominee
          </Option>
          <Option value="Shortlisted">Shortlisted</Option>
        </>
      ) : record?.groupStage === "Shortlisted" ? (
        <>
          <Option disabled={true} value="Shortlisted">
            Shortlisted
          </Option>
          <Option value="Finalists">Finalists</Option>
        </>
      ) : (
        <Option>{record?.groupStage}</Option>
      )}
    </Select>
  );

}

export const GroupRole = ({ record, getApi, text, user, details }) => {
  return (<Select
    style={{ width: "130px" }}
    value={text}
    disabled={
      user?.privilege === "EventCoordinators" || user?.role === "SuperAdmin"
        ? record?.groupName === null
          ? false
          : true
        : true
    }
    onSelect={(val) => {
      Api.patch("/ibelong-events/updateGroupRole")
        .params({ eventId: details?.id, userId: record?.id, role: val })
        .send((res) => {
          getApi();
        });
    }}
  >
    <Option value="Associate">Associate</Option>
    <Option value="Group Leader">Group Leader</Option>
  </Select>)
}

export const GroupParticipantMob = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  console.log("showDetails", props.item);
  return (
    <div>
      <div
        className={classes.UserData}
        onClick={() => setShowDetails((prev) => !prev)}
      >
        <div>
          <img
            src={
              props?.item?.profileImage
                ? props?.item?.profileImage
                : profileIcon
            }
          />
        </div>

        <span>{props?.item?.name}</span>
        <div>
          {!showDetails && (
            <RightOutlined
              style={{ fontWeight: "bold", fontSize: "18px", color: "grey" }}
            />
          )}
          {showDetails && (
            <DownOutlined
              style={{ fontWeight: "bold", fontSize: "18px", color: "grey" }}
            />
          )}
        </div>
      </div>
      {showDetails && (
        <div className={classes.restDetails}>
          <div>
            <span>Employee Id</span>
            <span>{props?.item?.uid}</span>
          </div>
          <div>
            <span>Contact Details</span>
            <div className={classes.contacts}>
              <span>{props?.item?.phone}</span>
              <span>
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{ width: "9rem", color: "gray", fontSize: "13px" }}
                >
                  {props?.item?.email}
                </Typography.Text>
              </span>
            </div>
          </div>
          <div>
            <span>Department</span>
            <span>{props?.item?.department ? props?.item?.department : "NA"}</span>
          </div>

          <div>
            <span>House</span>
            <span>{props?.item?.houseName}</span>
          </div>

          <div >
            <span  >Group Name</span>
            <span>
              <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "7rem" }}>{props?.item?.groupName ? props?.item?.groupName : "NA"} </Typography.Text>
            </span>
          </div>

          <div>
            <span>Group Role</span>
            <GroupRole record={props?.item} getApi={props?.getApi} text={props?.item.groupRole} user={props?.user} details={props?.details} />
          </div>

          <div>
            <NavLink
              to={{
                pathname: "/userProfile",
                state: { data: props?.item },
              }}
            >
              <a>View more</a>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

const EventDetails = () => {
  const [groupDetails, setGroupDetails] = useState('')
  const [groupRole, setGroupRole] = useState('')
  const [groupLeaderUpload, setGroupLeaderUpload] = useState(false)
  const [refreshRow, setRefreshRow] = useState(false);
  const history = useHistory();
  const [addMedia, setAddMedia] = useState([]);
  const [event, setEvent] = useState([]);
  const [details, setDetails] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [modVis, setModVis] = useState(false);
  let location = useLocation();
  const [visible, setVisible] = useState(true);
  const [visiblePdf, setVisiblePdf] = useState(true);
  const [selected, setSelected] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [organisatrionPosts, setOrganisationPosts] = useState();
  const [allParticpants, setAllparticipants] = useState(true);
  const dispatch = useDispatch();
  const { user, openTeamModal, openTeamId, viewGroupInfo } = useSelector(
    (state) => state.auth
  );
  const [deleteModal, setDeletModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  // const [UploadAnyFile, setUploadAnyFile] = useState(false)
  const [addImageModal, setImageModal] = useState(false);
  const [imageData, setImageData] = useState();
  const [eventType, setEventType] = useState(false);
  const [search, setSearch] = useState("");
  const [participantType, setparticipantType] = useState("");
  const [groupModal, setGrpModal] = useState(false);
  const [participantArray, setParticipantArray] = useState([]);
  const [groupLeaderId, setGroupLeaderId] = useState("");
  const [gpLoader, setGpLoader] = useState(false);
  const [grouploader, setGrouploader] = useState(false);
  const [participantbtnLoader, setbtnedLoader] = useState(false);
  const [groupParticipant, setGrpParticipant] = useState([]);
  const [playVideomedia, setPlay] = useState("");
  const { Paragraph, Text } = Typography;
  const [ellipsis, setEllipsis] = useState(true);
  const [status, setStatus] = useState("");
  const [optional, setOptional] = useState(true);
  const [userdata, setUserData] = useState("");
  const [downloadData, setDownloadData] = useState("");
  const [group, setGroup] = useState("");
  const [individual, setIndividual] = useState(false);
  const [groupOpenmodal, setGroupOpenmodal] = useState(false);
  const [selectWinner, setSelectWinner] = useState(false);
  const [candidateUpload, setcandidateUpload] = useState(false);
  const [candsMedia, setCandMedia] = useState("");
  const [groupsMedia, setGroupsMedia] = useState("");
  const [groupUpload, setgroupUpload] = useState(false);
  const [hideDownload, setHideDownload] = useState(true);
  const [winnerSlected, setWinnerSelected] = useState([]);
  const [winnerLoader, setWinnerLoader] = useState(false);
  const [submitWinner, setSubmitWinner] = useState(false);
  const [id, setId] = useState("");
  const [uploaderId, setUploaderId] = useState("");
  const [groupsId, setGroupsId] = useState("");
  const [nominationdates, setNominationDate] = useState("");
  const [startDate, setStartdate] = useState("");
  const [endDate, setEnddate] = useState("");
  const [NominationStartDate, setNominationEnddate] = useState("");
  const [eventValChanged, setEventValChanged] = useState(false);
  // const [eventId,setEventId]=useState(null);
  const [winner1st, setWinner1st] = useState("0");
  const [winner2nd, setWinner2nd] = useState("0");
  const [winner3rd, setWinner3rd] = useState("0");
  const [totalPoint, setTotalPoint] = useState();
  const [imageUrl, setImageUrl] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCreateGrp, setShowCreateGrp] = useState(false);
  const [groupId, setGroupId] = useState("");
  const { width } = useWindowDimensions();
  const [groupActionModal, setGroupActionModal] = useState(false);
  const [editGrpData, setEditGrpData] = useState();
  const [removedGrpParticipants, setRemovedGrpParticipants] = useState([]);
  const [Tab, setTab] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [groupList,setGroupList]=useState();
  // const handleRadioChange = (e) => {
  //   const value = e.target.value;
  //   setParticipantType(value);
  // };

  const removeImage = (index) => {
    setVisible((prev) => !prev);
    setImageData("");
    setImageUrl("");

    message.success("Images removed");
  };
  const removePdf = () => {
    setVisiblePdf((prev) => !prev);
    setPdfUrl("");
    message.success("Pdf removed");
  };

  const UploadButton = (
    <div style={{ color: "#6FC78F", cursor: "pointer" }}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      Click to Upload
    </div>
  );
  useEffect(() => {
    if (modalOpen) {
      document.getElementById('dashboardevent').style.overflow = 'hidden'
    } else {
      document.getElementById('dashboardevent').style.overflow = 'auto'
    }

  })

  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return isJpgOrPng || Upload.LIST_IGNORE;
    } else if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
      return isJpgOrPng || Upload.LIST_IGNORE;
    } else if (imageUrl.length >= 5) {
      message.error("Number of files uploaded should not be greater than 5!");
    } else {
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl((prev) => [...prev, url]);
        setImgFile(file);
      });
    }
  };

  const pdfbeforeUpload = (file) => {
    const isPdf = file.type === "application/pdf";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isPdf) {
      message.error("You can only upload PDF files");
      return isPdf || Upload.LIST_IGNORE;
    } else if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
      return isPdf || Upload.LIST_IGNORE;
    } else {
      getBase64(file, (url) => {
        setLoading(false);
        setPdfUrl(url);
      });
    }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  // const groupName = () => {

  const columnsGroup = [
    {
      title: "Group Name",
      dataIndex: "groupName",
      key: "groupName",
      render: (text, record) => {
        return (
          <div>
            <Typography.Text className={classes.colItem} ellipsis={{ tooltip: true }} style={{ maxWidth: "10vw" }} > {text}</Typography.Text>
          </div>
        );
      },
    },
    {
      title: "Contact Details",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        return (
          <>
            <p className={classes.colItem}>{record?.createdBy?.name}</p>
            <p className={classes.colItem}>{record?.createdBy?.phone}</p>
          </>
        );
      },
    },
    {
      title: "Group Stage",
      dataIndex: "groupStage",
      key: "groupStage",
      render: (text, record) => {
        let disabled = true;
        if (record?.groupStage === "Nominee") {
          if (user?.role === "Captain" || user?.role === "ViceCaptain" || user?.role === 'SuperAdmin') {
            disabled =
              details?.fileType === "none"
                ? false
                : record?.posts?.length === 0
                  ? true
                  : false;
          }
        } else if (record?.groupStage === "Shortlisted") {
          if (
            user?.privilege === "Admin" ||
            user?.role === "SuperAdmin" ||
            user?.privilege === "EventOrganisers"
          ) {
            disabled = false;
          }
        }
        return (
          <Select
            disabled={disabled}
            value={record?.groupStage}
            onChange={(e) => {
              if (e === "Shortlisted" || e === "Finalists") {
                Api.patch("/ibelong-events/groupStatus")
                  .params({ groupId: record?.id, stage: e })
                  .send((response) => {
                    getGroups();
                  });
              }
            }}
          >
            {record?.groupStage === "Nominee" ? (
              <>
                <Option disabled={true} value="Nominee">
                  Nominee
                </Option>
                <Option value="Shortlisted">Shortlisted</Option>
              </>
            ) : record?.groupStage === "Shortlisted" ? (
              <>
                <Option disabled={true} value="Shortlisted">
                  Shortlisted
                </Option>
                <Option value="Finalists">Finalists</Option>
              </>
            ) : (
              <Option>{record?.groupStage}</Option>
            )}
          </Select>


        );
      },
    },

    {
      title: "Files",
      key: "files",
      dataIndex: "files",
      render: (text, record) => {
        if (details?.fileType === "none" || details?.fileType === null) {
          return <p>N/A</p>;
        }
        // if (
        //   record?.groupStage === "Shortlisted" &&
        //   record?.posts?.length === 1 &&
        //   optional === true
        // ) {
        //   return (
        //     <>
        //       <Space>
        //         <Button
        //           disabled={
        //             details?.nominationStatus
        //               ? user?.privilege === "EventCoordinators" ||
        //                 user?.id === record?.createdBy?.id
        //                 ? false
        //                 : true
        //               : true
        //           }
        //           className={classes.colItem}
        //           onClick={() => {
        //             store.dispatch(houseActions.openVideoModal(true));
        //             store.dispatch(houseActions?.groupDetails(record));
        //             setUploaderId(record?.createdBy?.id);
        //             console.log("sssrec", record)
        //           }}
        //         >
        //           Upload{" "}
        //           {details?.fileType === "application"
        //             ? "Pdf"
        //             : details?.fileType}
        //         </Button>
        //         <Button onClick={() => setOptional(false)}>X</Button>
        //       </Space>
        //     </>
        //   );
        // }
        if (record?.posts !== null && record?.posts?.length !== 0) {
          return (
            <div>
              <a
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  // setgroupUpload(true);
                  setVideoModal(true);
                  store.dispatch(houseActions.openVideoModal(true));
                  // setGroupsMedia({fileType: record?.event?.fileType,record: record,});
                  setAddMedia(record?.posts);
                  store.dispatch(houseActions?.groupDetails(record));
                  setUploaderId(record?.createdBy?.id);
                  setGroupDetails(record);
                  console.log("sssrec", record)
                }}
              >
               {(user?.privilege === "EventCoordinators" || user?.role === "SuperAdmin" || groupRole === "Group Leader" ||user?.id === record?.createdBy?.id)?'Upload additional files':'View files'} 
              </a>

            </div>
          );
        }
        return (
          <>
              <Button
                disabled={
                  details?.nominationStatus
                    ? ((user?.privilege === "EventCoordinators" || user?.role === "SuperAdmin" || groupRole === "Group Leader" ||user?.id === record?.createdBy?.id)
                      ? false
                      : true)
                    : user?.role !== "SuperAdmin"
                }
                className={classes.colItem}
                onClick={() => {
                  store.dispatch(houseActions.openVideoModal(true));
                  store.dispatch(houseActions?.groupDetails(record));
                  setUploaderId(record?.createdBy?.id);
                  setGroupsId(record?.id)
                  setGroupId(record?.id)
                  console.log("sssrec", record)
                }}
              >
                Upload{" "}
                {record?.event?.fileType === "application"
                  ? "Pdf"
                  : record?.event?.fileType}
              </Button >
            
          </>
        );
      },
    },
    {
      title: "Action",
      key: "view",
      dataIndex: "view",
      render: (text, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", paddingLeft: "10px" }}>
            <div
              onClick={() => {
                setGroupOpenmodal(true);
                setId(record?.id);
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                cursor: "pointer",
                // border:"2px solid red solid red",
              }}
            >
              <EyeOutlined></EyeOutlined>
              <div style={{ paddingRight: "10px" }}>View Group List</div>
            </div>


            {/* {user?.privilege === "EventCoordinators" ? <div>
              <div
                onClick={() => {
                  Api.get(`/events/getGroupUsers/?groupId=${record.id}`).send(
                    (res) => {
                      if (!res) {
                        console.log("SOME ERROR OCCURED");
                      } else {
                        dispatch(authActions.updateViewGroupInfo(res));
                      }
                    }
                  );
                  setGroupActionModal(true);
                  setEditGrpData(record);
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  cursor: "pointer",
                  borderLeft: "1px solid grey",
                  paddingLeft: "10px",
                }}
              >
                <img src={EditButton} style={{ width: "11px", height: "11px" }} />
                <div>Edit </div>
              </div></div> : <div> </div>} */}
            {console.log("junoGrprole", groupRole === "Group Leader")}
            {
              user?.privilege === "EventCoordinators" || user?.role === 'SuperAdmin' ? <div>
                <div
                  onClick={() => {
                    Api.get(`/ibelong-events/getGroupUsers/?groupId=${record.id}`).send(
                      (res) => {
                        if (!res) {
                          console.log("SOME ERROR OCCURED");
                        } else {
                          dispatch(authActions.updateViewGroupInfo(res));
                          // console.log("THIS IS RESPONSE:",res);
                        }
                      }
                    );
                    setGroupActionModal(true);
                    setEditGrpData(record);
                    // console.log("data is coming",editGrpData);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    alignItems: "center",
                    cursor: "pointer",
                    borderLeft: "1px solid grey",
                    paddingLeft: "10px",
                  }}
                >
                  <img src={EditButton} style={{ width: "11px", height: "11px" }} />
                  <div>Edit </div>
                </div></div> : <div> </div>
            }


          </div>
        );
      },
    },
  ];
  const columnsTable = [
    {
      title: "Associate",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const condition =
          user?.role === "SuperAdmin" ||
          user?.privilege === "EventOrganisers" ||
          user?.house === record?.houseName;
        const content = (
          <div className={classes.evenCol}>
            <div className={classes.col}>
              <img
                src={
                  record.profileImage !== null
                    ? record?.profileImage
                    : profileIcon
                }
                alt="userimage"
              />
            </div>
            <div className={classes.col}>
              <h2 style={{ minWidth: "12vw" }}>{text}</h2>
              <p className={classes.colItem}>{record?.uid}</p>
            </div>
          </div>
        );
        return (
          <div>
            {!condition && <p className={classes.colItem}>{content}</p>}
            {condition && (
              <NavLink
                to={{ pathname: "/userProfile", state: { data: record } }}
                className={classes.userProfile}
              >
                {content}
              </NavLink>
            )}
          </div>
        );
      },
    },
    {
      title: "Contact Details",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        return (
          <>
            <p className={classes.colItem}>{text}</p>
            <p className={classes.colItem}>{record?.phone}</p>
          </>
        );
      },
    },
    {
      title: "House",
      dataIndex: "houseName",
      key: "description",
      render: (text) => {
        return <p className={classes.colItem}>{text}</p>;
      },
    },

    {
      title: "Department",
      key: "department",
      dataIndex: "department",
      render: (text) => {
        return <p className={classes.colItem}>{text ? text : "NA"}</p>;
      },
    },

    {
      title: "Group Name",
      key: "groupName",
      dataIndex: "groupName",
      render: (text) => {
        return <p className={classes.colItem}>
          <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: "10vw" }}>  {text}</Typography.Text>
        </p>;
      },
    },
    {
      title: "Group Role",
      key: "groupRole",
      dataIndex: "groupRole",
      render: (text, record) => {
        return (
          <Select
            style={{ width: "130px" }}
            value={text}
            disabled={
              user?.privilege === "EventCoordinators" || user?.role === "SuperAdmin"
                ? record?.groupName === null
                  ? false
                  : true
                : true
            }
            onSelect={(val) => {
              Api.patch("/ibelong-events/updateGroupRole")
                .params({ eventId: details?.id, userId: record?.id, role: val })
                .send((res) => {
                  getApi();
                });
            }}
          >
            <Option value="Associate">Associate</Option>
            <Option value="Group Leader">Group Leader</Option>
          </Select>
        );
      },
    },
  ];


  const columns = [
    {
      title: "Associate",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const condition =
          user?.role === "SuperAdmin" ||
          user?.privilege === "EventOrganisers" ||
          user?.house === record?.houseName;
        const content = (
          <div className={classes.evenCol}>
            <div className={classes.col}>
              <img
                src={record?.profileImage ? record?.profileImage : profileIcon}
                alt=""
              />
            </div>
            <div className={classes.col}>
              <h2 style={{ minWidth: "12vw" }}>{text}</h2>
              <p className={classes.colItem}>{record?.uid}</p>
            </div>
          </div>
        );
        return (
          <div>

            {!condition && <p className={classes.colItem}>{content}</p>}
            {condition && (
              <NavLink
                to={{ pathname: "/userProfile", state: { data: record } }}
                className={classes.userProfile}
              >
                {content}
              </NavLink>
            )}
          </div>
        );
      },
    },
    {
      title: "Contact Details",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        console.log("record1", text, record?.phone)
        return (
          <>
            <p className={classes.colItem}>{text}</p>
            <p className={classes.colItem}>{record?.phone}</p>
          </>
        );
      },
    },
    {
      title: "House",
      dataIndex: "houseName",
      key: "description",
      render: (text) => {
        return <p className={classes.colItem}>{text}</p>;
      },
    },

    {
      title: "Department",
      key: "department",
      dataIndex: "department",
      render: (text) => {
        return <p className={classes.colItem}>{text ? text :"NA"}</p>;
      },
    },
    {
      title: "Event Stage Reached",
      key: "stage",
      dataIndex: "stage",
      render: (text, record) => {
        let disabled = true;

        if (record?.stage === "Nominee") {
          if (user?.role === "Captain" || user?.role === "ViceCaptain" || user?.role === 'SuperAdmin') {
            disabled =
              details?.fileType === "none"
                ? false
                : record?.media === null
                  ? true
                  : false;
          }
        } else if (record?.stage === "Shortlisted") {
          if (
            user?.privilege === "Admin" ||
            user?.role === "SuperAdmin" ||
            user?.privilege === "EventOrganisers"
          ) {
            disabled = false;
          }
        }
        return (
          <Select
            disabled={disabled}
            value={record?.stage}
            onChange={(e) => {
              if (e === "Shortlisted" || e === "Finalists") {
                Api.post("/ibelong-events/participate")
                  .params({
                    eventId: details?.id,
                    participantId: record?.id,
                    stage: e,
                  })
                  .send((response) => {
                    getApiWithSearch();
                  });
              }
            }}
          >
            {record?.stage === "Nominee" ? (
              <>
                <Option disabled={true} value="Nominee">
                  Nominee
                </Option>
                <Option value="Shortlisted">Shortlisted</Option>
              </>
            ) : record?.stage === "Shortlisted" ? (
              <>
                <Option disabled={true} value="Shortlisted">
                  Shortlisted
                </Option>
                <Option value="Finalists">Finalists</Option>
              </>
            ) : (
              <Option>{record?.stage}</Option>
            )}
          </Select>
        );


      },
    },
    {
      title: <div>Files</div>,
      dataIndex: "fileType",
      key: "files",
      render: (text, record) => {
        console.log('rrre', record.id, 'uuu', user?.id)
        let disableCondition = false;
        if (details?.fileType === "none" || details?.fileType === null) {
          return <p>N/A</p>;
        }
        if (record?.media !== null && record?.media?.length !== 0) {
          return (
            <Row>
              <a
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  console.log("wwwwwwrecord", record?.id);
                  setVideoModal(true);
                  setAddMedia(record?.media);
                  setUploaderId(record?.id);
                  setGroupDetails(record)
                  console.log("sssrec", record)
                }}
              >
               {(user?.id !== record?.id && user?.role !== "SuperAdmin")?'view files':'upload additional files'}
              </a>
            </Row>
          );
        }
        return (
          <>
            {user?.id !== record?.id && user?.role !== "SuperAdmin"? (
              <div style={{ paddingLeft: "10px" }}>N/A</div>
            ) : (
              <Button
                disabled={
                 user?.role !== "SuperAdmin" 
                 && !details?.nominationStatus
                }
                className={classes.colItem}
                onClick={() => {
                  setAddMedia([]);
                  setVideoModal(true);
                  setUploaderId(record?.id);
                  console.log("sssrec", record?.id)

                }}
              >

                Upload{" "}
                {details?.fileType === "application"
                  ? "Pdf"
                  : details?.fileType}
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const Menu = (list, selected) =>
    list?.map((el) => {
      return (
        <Card
          onClick={() => {
            setImageModal(true);
            setImageData(el);
          }}
          style={{ margin: "5px", cursor: 'pointer', }}
          className="modal"
        >
          <img src={el.img} style={{ width: "90px", height: "90px", }} />
        </Card>
      );
    });

  const Arrow = ({ text, className }) => {
    return <div style={{ fontSize: "30px", margin: 10 }}>{text}</div>;
  };

  const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
  const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

  // All items component
  // Important! add unique key
  const onSelect = (key) => {
    setSelected(key);
  };
  const menuItems = Menu(organisatrionPosts, selected);
  const menu = menuItems;
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div>
      +<div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  let values;
  if (location.state !== null) {
    values = location?.state?.data;
  }
  const getApi = () => {
    setGpLoader(true);
    setShowCreateGrp(false);
    Api.get(`/ibelong-events/eventMaster/${values.eventId}?search=`).send((res) => {
      if (res?.event?.eventType === "Both") {
        setTab("Individual");
      }
      res?.notInterestParticipants?.forEach((ele) => {
        if (ele?.id === user?.id) {
          setAllparticipants(false);
          return;
        }
      });
      const present = res?.individualParticipants.find(
        (element) => element.id === user.id
      );
      const grpPresent = res?.groupParticipants.find(
        (element) => element.id === user.id
      );
      if (present || grpPresent) {
        setAllparticipants(false);
      }
      const participants = res?.individualParticipants.filter(
        (element) => element.stage !== "NotInterested"
      );
      setEvent(participants);
      setDetails(res?.event);
      setOrganisationPosts(res?.eventImages);
      setGrpParticipant(res?.groupParticipants);
      setParticipantArray(res?.groupParticipantDropdown);
      setGpLoader(false);

      res?.groupParticipants?.forEach((val) => {
        if (val?.id === user?.id) {
          setGroupRole(val?.groupRole)
          if (val?.groupRole === "Group Leader" && val?.groupStatus === false) {
            setShowCreateGrp(true);
          }
          if (val?.groupRole === "Group Leader") {
            setGroupLeaderUpload(true)
            setGroupRole(val?.groupRole)
          }
        }
      });
    });
    setRefreshRow(true);
  };
  const getApiWithSearch = () => {
    setGpLoader(true);
    setShowCreateGrp(false);
    Api.get(`/ibelong-events/eventMaster/${values.eventId}?search=${search}`).send((res) => {
      if (res?.event?.eventType === "Both") {
        setTab("Individual");
      }
      res?.notInterestParticipants?.forEach((ele) => {
        if (ele?.id === user?.id) {
          setAllparticipants(false);
          return;
        }
      });
      const present = res?.individualParticipants.find(
        (element) => element.id === user.id
      );
      const grpPresent = res?.groupParticipants.find(
        (element) => element.id === user.id
      );
      if (present || grpPresent) {
        setAllparticipants(false);
      }
      const participants = res?.individualParticipants.filter(
        (element) => element.stage !== "NotInterested"
      );
      setEvent(participants);
      setDetails(res?.event);
      setOrganisationPosts(res?.eventImages);
      setGrpParticipant(res?.groupParticipants);
      setParticipantArray(res?.groupParticipantDropdown);
      setGpLoader(false);

      res?.groupParticipants?.forEach((val) => {
        if (val?.id === user?.id) {
          setGroupRole(val?.groupRole)
          if (val?.groupRole === "Group Leader" && val?.groupStatus === false) {
            setShowCreateGrp(true);
          }
          if (val?.groupRole === "Group Leader") {
            setGroupLeaderUpload(true)
            setGroupRole(val?.groupRole)
          }
        }
      });
    });
    setRefreshRow(true);
  };

  useEffect(() => {
    if (details?.eventType !== "Individual") {
      getGroups();
    }
  }, []);

  const getGroups = () => {
    console.log("getgrppppp")
    setGrouploader(true);
    Api.get(`/ibelong-events/allGroups`)
      .params({
        eventId: values?.eventId !== undefined ? values?.eventId : values?.id,
      })
      .send((res) => {
        setEvent(res?.groups);
        setGroupList(res?.groups)
        setGrouploader(false);
      });
  };


  useEffect(() => {
    // setWinnerLoader(true);
    setShowCreateGrp(false);
    console.log("tabone", Tab)
    Api.get(`/ibelong-events/eventMaster/${values?.eventId !== undefined ? values?.eventId : values?.id}`).params({ search: search, }).send((res) => {
      console.log("grrr11111", search, res);
      if (res?.event?.eventType === "Both" && Tab === 'Individual') {
        setTab("Individual");
      }
      else if (res?.event?.eventType === "Both" && Tab === 'Group') {
        setTab("Group");
      }
      setDetails(res?.event);
      setWinner1st(res?.event?.firstWinnerPoints);
      setWinner2nd(res?.event?.secondWinnerPoints);
      setWinner3rd(res?.event?.thirdWinnerPoints);
      setNominationDate(res?.event?.nominationdates);
      setStartdate(res?.event?.startDate);
      setEnddate(res?.event?.endDate);
      setNominationEnddate(res?.event?.NominationStartDate);
      setStatus(res?.event?.status);

      res?.notInterestParticipants?.forEach((ele) => {
        if (ele?.id === user?.id) {
          setAllparticipants(false);
          return;
        }
      });
      Api.get("/ibelong-events/getWinners")
        .params({ eventId: res?.event?.id })
        .send((res) => {
          setWinnerSelected(res);
          setWinnerLoader(false);
        });
      const present = res?.individualParticipants.find((element) => {
        return element.id === user.id;
      });
      const grpPresent = res?.groupParticipants.find(
        (element) => element.id === user.id
      );
      if (present || grpPresent) {
        setAllparticipants(false);
      }
      const participants = res?.individualParticipants.filter(
        (element) => element.stage !== "NotInterested"
      );
      setEvent(participants);
      setGrpParticipant(res?.groupParticipants);
      setOrganisationPosts(res?.eventImages);
      let groupLeader = res?.groupParticipants?.filter(item => item.groupRole === "Group Leader")?.[0]?.id;
      console.log("groupLeader", groupLeader)
      setGroupLeaderId(groupLeader);
      setParticipantArray(res?.groupParticipantDropdown);

      res?.groupParticipants?.forEach((val) => {
        if (val?.id === user?.id) {
          if (
            val?.groupRole === "Group Leader" &&
            val?.groupStatus === false
          ) {
            setShowCreateGrp(true);
          }
        }
      });
    });
  }, [search, status, submitWinner, Tab]);
  console.log("sssDetails", details)
  useEffect(() => {
    console.log("download1Tab")
    if (details?.eventType === "Individual") {
      console.log("tabset1", details?.eventType)
      setGroup("Individual");
    }
    if (details?.eventType === "Group") {
      console.log("tabset11", details?.eventType)
      setGroup("Group");
    }
    if (details?.eventType === "Both") {
      // setGroup("Individual");
      if (Tab === "Group") {
        console.log("tabset110", details?.eventType)
        setGroup("Group");
      }
      else {
        console.log("tabset1100", details?.eventType)
        setGroup("Individual");
      }
      console.log("tabset111", group, Tab, details?.eventType, search)
    }
  }, [details][Tab]);
  const switchHandler = (e) => {
    let data;
    if (!e) {
      data = { eventId: details?.id, status: "InActive" };
      setStatus("InActive");
    } else {
      data = { eventId: details?.id, status: "Active" };
      setStatus("Active");
    }
    Api.patch("/ibelong-events/updateEvent")
      .params(data)
      .send((res) => {
        history.push("/events");
      });
  };

  const editEventHandler = (values) => {
    // if(values?.details?.media?.length===0){
    //   message.error("please upload Event Image","error")
    //   return ;
    // }
    //  if(values?.files?.fileList?.length>5){
    //   Notification.error("please upload only 5 image","error")
    //   return ;
    // }
    // if(values?.guidelinesFile?.fileList?.length===0){
    //   message.error("please upload guideline file","error")
    //   return ;
    // }
    if (
      parseInt(values?.secondWinnerPoints) >=
      parseInt(values?.firstWinnerPoints)
    ) {
      Notification.error("Error",
        "Points for 1st winner should be greater than 2nd winner"
      );
      return;
    }
    if (
      parseInt(values?.thirdWinnerPoints) >=
      parseInt(values?.secondWinnerPoints)
    ) {
      Notification.error("Error",
        "Points for 2nd winner should be greater than 3rd winner"      );
      return;
    }
    if (Date.parse(NominationStartDate) > Date.parse(nominationdates)) {
      Notification.error("Error",
        "Event Nomination Start Date should be earlier than End Date"
      );
      return;
    }
    if (Date.parse(startDate) > Date.parse(endDate)) {
      Notification.error("Error",
        "Event Start Date should be earlier than End Date"
      )
      return;
    }
    if (Date.parse(NominationStartDate) > Date.parse(startDate)) {
      Notification.error("Error",
        "Nomination Start Date should be earlier than Event Start Date"
      );
      return;
    }
    if (Date.parse(nominationdates) > Date.parse(endDate)) {
      Notification.error("Error",
        "Nomination End Date should be earlier than Event End Date"
      );
      return;
    }
    if (Date.parse(nominationdates) > Date.parse(startDate)) {
      Notification.error("Error",
        "Nomination End Date should be earlier than Event Start Date"
      );
      return;
    }
    let data = {};

    if (values?.files !== undefined && values?.files?.fileList?.length > 0) {
      let files = [];
      values?.files?.fileList.forEach((val, index) => {
        files.push(val?.originFileObj);
      });
      data = { ...data, files: files };
    }
    if (values?.title !== details?.title) {
      if (values?.title?.trim()?.length === 0) {
        Notification.error("Error","Please give event name");
        return;
      }
      data = { ...data, title: values?.title };
    }
    if (moment(values?.actualDate).diff(details?.actualDate, "day")) {
      data = { ...data, actualDate: values?.actualDate };
    }
    if (moment(values?.nominationDate).diff(details?.nominationDate, "day")) {
      data = { ...data, nominationDate: values?.nominationDate };
    }
    if (moment(values?.startDate).diff(details?.startDate, "day")) {
      data = { ...data, startDate: values?.startDate };
    }
    if (moment(values?.endDate).diff(details?.endDate, "day")) {
      data = { ...data, endDate: values?.endDate };
    }
    if (values?.description !== details?.description) {
      if (values?.description?.trim()?.length === 0) {
        Notification.error("Error","Please give event description");
        return;
      }
      data = { ...data, description: values?.description };
    }
    if (values?.guidelinesFile !== details?.guidelinesFile) {
      data = {
        ...data,
        guidelines: values.guidelinesFile.fileList[0].originFileObj,
      };
    }
    if (values?.firstWinnerPoints !== details?.firstWinnerPoints) {
      data = { ...data, firstWinnerPoints: values.firstWinnerPoints };
    }
    if (values?.secondWinnerPoints !== details?.secondWinnerPoints) {
      data = { ...data, secondWinnerPoints: values.secondWinnerPoints };
    }
    if (values?.thirdWinnerPoints !== details?.thirdWinnerPoints) {
      data = { ...data, thirdWinnerPoints: values.thirdWinnerPoints };
    }

    if (
      parseInt(values?.secondWinnerPoints) >=
      parseInt(values?.firstWinnerPoints)
    ) {
      Notification.error("Error",
        "Points for 1st winner should be greater than 2nd winner"
        
      );
      return;
    }
    if (
      parseInt(values?.thirdWinnerPoints) >=
      parseInt(values?.secondWinnerPoints)
    ) {
      Notification.error("Error",
        "Points for 2nd winner should be greater than 3rd winner"
      );
      return;
    }
    if (Date.parse(values?.actualDate) > Date.parse(values?.nominationDate)) {
      Notification.error("Error",
        "Event Nomination Start Date should be earlier than End Date"
      );
      return;
    }
    if (Date.parse(values?.startDate) > Date.parse(values?.endDate)) {
      Notification.error("Error",
        "Event Start Date should be earlier than End Date"
      );
      return;
    }
    if (Date.parse(values?.actualDate) > Date.parse(values?.startDate)) {
      Notification.error("Error",
        "Nomination Start Date should be earlier than Event Start Date"
      );
      return;
    }
    if (Date.parse(values?.nominationDate) > Date.parse(values?.endDate)) {
      Notification.error("Error",
        "Nomination End Date should be earlier than Event End Date"
      );
      return;
    }
    if (Date.parse(values?.nominationDate) > Date.parse(values?.startDate)) {
      Notification.error("Error",
        "Nomination End Date should be earlier than Event Start Date"
      );
      return;
    }

    // const finalValues={...data,  'actualDate': moment(data?.values?.actualDate.format('YYYY/MM/DD')),
    //                              'nominationDate':moment (data?.values?.nominationDate.format('YYYY/MM/DD')),
    //                                'startDate': moment(data?.values?.startDate.format('YYYY/MM/DD')),
    //                                  'endDate':moment(data?.values?.endDate.format('YYYY/MM/DD')),
    //                                   // 'guidelinesFile':values?.guidelinesFile?.fileList[0]?.originFileObj,
    //                                   //  files: data?.files
    //                                   };

    data = { ...data, eventId: details?.id };
    if (values?.files !== undefined || values?.guidelinesFile !== undefined) {
      Api.patch("/ibelong-events/updateEventDetails").uploadAndPatch(
        data,
        (percentCompleted, response) => {
          setModalOpen(false);

          if (percentCompleted === 100) {
            getApi();
          }
        }
      );
    } else {
      Api.patch("/ibelong-events/updateEventDetails")
        .params(data)
        .send((res) => {
          setModalOpen(false);
          getApi();
        });
    }
  };

  return (
    <div className={classes.dashboard} id="dashboardevent" >
      {details === "" ? (
        <>
          {width > 700 && <Row justify="center"><Spin size="large" style={{ alignSelf: "center", height: "10vh" }} /> </Row>}
          {width < 700 && <Row justify='center' style={{ width: '100vw', height: '100vh', }}><Spin size='large' style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} /></Row>}

        </>

      ) : (
        <div>
          <div >
            {width > 700 && <Row className={classes.back_button_row} >
              <Col span={13} style={{ marginLeft: "5px" }}>
                <div
                  className={classes["profile-header"]}
                  style={{ display: "flex", marginTop: "1rem" }}
                >
                  <div
                    onClick={() => {
                      history.goBack();
                    }}
                    style={{ cursor: "pointer", marginBottom: "-0.5rem" }}
                  >
                    <img src={backLogo} alt="back" />
                  </div>
                  <Typography.Text
                    className={classes.eventDescription}
                    ellipsis={{ tooltip: true }}
                  >
                    {details?.title}
                  </Typography.Text>
                </div>
              </Col>

              <Col span={4} offset={0}>
                <div
                  className={classes["profile-header"]}
                  style={{
                    display: "flex",
                    marginLeft: "30px",
                    marginTop: "-0rem",
                  }}
                >
                  {(user.role === "SuperAdmin" ||
                    user.privilege === "Admin" ||
                    user.privilege === "EventOrganisers") && (
                      <Switch
                        checked={status === "Active" ? true : false}
                        checkedChildren="Active"
                        unCheckedChildren="InActive"
                        onClick={switchHandler}
                        style={{ margin: "13px 0 0 20px" }}
                      ></Switch>
                    )}
                </div>
              </Col>
              <Col span={4} offset={0}>
                <div
                  onClick={() => window.open(details?.guidelines)}
                  className={classes.guidelinebtn}
                >
                  Guidelines{" "}
                  <div>
                    <DownloadOutlined />
                  </div>
                </div>
              </Col>
            </Row>}

            {width < 700 &&
              <>
                <div
                  className={classes["profile-header"]}
                  style={{ display: "flex", marginTop: "1rem" }}
                >
                  <div
                    onClick={() => {
                      history.goBack();
                    }}
                    style={{ cursor: "pointer", marginBottom: "-0.5rem" }}
                  >
                    <img src={backLogo} alt="back" />
                  </div>
                  <Typography.Text
                    style={{ fontSize: "1.2rem", fontWeight: "600" }}
                    className={classes.eventDescription}
                    ellipsis={{ tooltip: true }}
                  >
                    {details?.title}
                  </Typography.Text>
                </div>

                <div style={{ margin: "1rem auto" }} >
                  <div className={classes["profile-header"]}  >
                    {(user.role === "SuperAdmin" ||
                      user.privilege === "Admin" ||
                      user.privilege === "EventOrganisers") && (
                        <Switch
                          checked={status === "Active" ? true : false}
                          checkedChildren="Active"
                          unCheckedChildren="InActive"
                          onClick={switchHandler}
                        ></Switch>
                      )}
                  </div>
                </div>
                <div
                  onClick={() => window.open(details?.guidelines)}
                  className={classes.guidelinebtn}
                >
                  Guidelines{" "}
                  <div>
                    <DownloadOutlined />
                  </div>
                </div>
              </>}

            {width > 700 && <Row className={classes.mainEventRow}>
              <Col span={17} className={classes.eventCol}>
                <Row className={classes.mainEventRow2}>
                  <Col span={5} offset={1}>
                    <img
                      src={details?.media[0]?.[0]}
                      className={classes.eventImage}
                      alt=""
                    />
                  </Col>
                  <Col span={17} className={classes.eventInfo}>
                    <Row className={classes.startDate} 
                    style={{display:"flex",flexDirection:"row",gap:"0.5rem"}}>
                      <div>Start date:</div>
                      <div>
                        <b>{details?.startDate}</b>
                      </div>
                      <div style={{ marginLeft:"2.5rem" }}>End date:</div>
                      <div>
                        <b>{details?.endDate}</b>
                      </div>
                      {(user.role === "SuperAdmin" ||
                        user.privilege === "Admin" ||
                        user.privilege === "EventOrganisers") && (
                          <img
                            src={EditButton}
                            onClick={() => {if(winnerSlected.length > 0){}else{setModalOpen(true)}}}
                            style={{
                              marginLeft: "80px",
                              width: "17px",
                              height: "17px",
                              display: "flex",
                              cursor:winnerSlected.length > 0 ? "not-allowed" :"pointer"
                            }}
                          ></img>
                        )}
                      <Modal
                        centered
                        open={modalOpen}
                        onCancel={() => {
                          setModalOpen(false);
                        }}
                        footer={null}
                        closable={true}
                        maskClosable={true}
                        width="700px"
                        margin="0"
                      >
                        <Form
                          layout="vertical"
                          form={form}
                          className={classes.modalDiv}
                          id="editEventForm"
                          onFinish={editEventHandler}
                          initialValues={{
                            title: details?.title,
                            description: details?.description,
                            firstWinnerPoints: details?.firstWinnerPoints,
                            secondWinnerPoints: details?.secondWinnerPoints,
                            thirdWinnerPoints: details?.thirdWinnerPoints,
                            actualDate: moment(details?.actualDate),
                            nominationDate: moment(details?.nominationDate),
                            startDate: moment(details?.startDate),
                            endDate: moment(details?.endDate),
                          }}
                        >
                          <h1>Edit Event</h1>
                          {/* {visible && (<div style={{backgroundColor:'#ebe9e9', width:'100%', display:'flex', padding:'5px', alignItems:'center', gap:'10px', justifyContent:'space-evenly'}} id="imageDiv">
                                {details?.media[0]?.map((data, index)=>(
                                  <div style={{display:'flex'}}>
                                    <img src={data} style={{width:'40px', height:'40px', marginRight:'20px'}}/>
                                    <div id="closeDiv" onClick={removeImage}><CloseOutlined /></div>
                                  </div>
                                ))}
                              </div>)} */}
                          <div>
                            {/* <Form.Item label="Image" name="files" required style={{width:'100%'}}>
                            
                                <Upload className={classes.modalSection} showUploadList={true} beforeUpload={imgbeforeUpload} onRemove={() => setImageUrl("")} maxCount={5} accept=".png, .jpg, .jpeg">
                                <div style={{fontFamily:'poppins',width:'600px', display:'flex', justifyContent:'center', alignItems:"center", gap:'5px'}}>Upload <UploadOutlined/></div>
                                </Upload>
                              </Form.Item> */}
                            <Form.Item
                              label="Name"
                              name="title"
                              className={classes.nameDiv}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Event Name",
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Enter name of the event"
                              />
                            </Form.Item>
                            <Row className={classes.dateDiv}>
                              <Form.Item
                                label="Nomination Start Date"
                                name="actualDate"
                                className={classes.firstDateDiv}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter Nomination Start Date",
                                  },
                                ]}
                                style={{ position: "relative" }}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  placeholder="dd-mm-yyyy"
                                  format={"YYYY-MM-DD"}
                                  placement="bottomRight"
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                  onChange={(date, dateString) => {
                                    setNominationEnddate(dateString);
                                  }}
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      current < moment().startOf("day")
                                    );
                                  }}
                                ></DatePicker>
                              </Form.Item>

                              <Form.Item
                                label="Nomination Final Date"
                                name="nominationDate"
                                className={classes.firstDateDiv}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Nomination End Date",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  placeholder="dd-mm-yyyy"
                                  format={"YYYY-MM-DD"}
                                  placement="bottomRight"
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      current <
                                      moment(
                                        NominationStartDate,
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                  onChange={(date, dateString) => {
                                    {
                                      setNominationDate(dateString);
                                    }
                                  }}
                                ></DatePicker>
                              </Form.Item>
                            </Row>

                            <Row className={classes.dateDiv}>
                              <Form.Item
                                label="Event Start Date"
                                name="startDate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Event Start Date",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  placeholder="dd-mm-yyyy"
                                  format={"YYYY-MM-DD"}
                                  className={classes.firstDateDiv}
                                  placement="bottomRight"
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                  onChange={(date, dateString) => {
                                    setStartdate(dateString);
                                  }}
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      current <
                                      moment(nominationdates, "YYYY-MM-DD")
                                    );
                                  }}
                                ></DatePicker>
                              </Form.Item>

                              <Form.Item
                                label="Event End Date"
                                name="endDate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Event End Date",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  placeholder="dd-mm-yyyy"
                                  format={"YYYY-MM-DD"}
                                  className={classes.firstDateDiv}
                                  placement="bottomRight"
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                  onChange={(date, dateString) => {
                                    setEnddate(dateString);
                                  }}
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      current < moment(startDate, "YYYY-MM-DD")
                                    );
                                  }}
                                ></DatePicker>
                              </Form.Item>
                            </Row>
                            <Form.Item
                              label=" Description"
                              name="description"
                              className={classes.nameDiv}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Description",
                                },
                              ]}
                            >
                              <TextArea
                                type="textareat"
                                placeholder="type here"
                                rows={3}
                              />
                            </Form.Item>
                            <Row className={classes.dateDiv}>
                              <Form.Item
                                label="Points for 1st winner"
                                name="firstWinnerPoints"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter points for 1st winner",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  min={0}
                                  onChange={(value) => {
                                    setWinner1st(value.target.value);
                                  }}
                                ></Input>
                              </Form.Item>

                              <Form.Item
                                label="Points for 2nd winner"
                                name="secondWinnerPoints"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter points for 2nd winner",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  min={0}
                                  onChange={(value) =>
                                    setWinner2nd(value.target.value)
                                  }
                                ></Input>
                              </Form.Item>
                            </Row>

                            <Row className={classes.dateDiv}>
                              <Form.Item
                                label="Points for 3rd winner"
                                name="thirdWinnerPoints"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter points for 3rd winner",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  min={0}
                                  onChange={(value) =>
                                    setWinner3rd(value.target.value)
                                  }
                                ></Input>
                              </Form.Item>

                              <Form.Item>
                                <b>Total points allocated- </b>{" "}
                                {parseInt(winner1st) +
                                  parseInt(winner2nd) +
                                  parseInt(winner3rd)}
                              </Form.Item>
                            </Row>
                            {/* <Form.Item label="Upload Guidelines" name="guidelinesFile"  >
                              <Upload name="file" className={classes.guidlineUpload} beforeUpload={pdfbeforeUpload}  showUploadList={true} style={{ backgroundColor: "#6FC78F", }} onRemove={()=>setPdfUrl("")} defaultFileList={details?.guidelinesFile}  maxCount={5} accept=".pdf ">
                              {  pdfUrl?.length>0?(<img src={pdfLogo} alt="Error" style={{width: "100%",height: "30px",borderRadius: "5%",}}/>) : (UploadButton)}
                              </Upload>
                            </Form.Item>*/}
                            <Form.Item className={classes.buttonDiv}>
                              <Button
                                onClick={() => {
                                  setModalOpen(false);
                                  document.forms[0].reset();
                                }}
                              >
                                DISCARD
                              </Button>
                              <Button
                                className={classes.addButton}
                                htmlType="submit"
                              >
                                SAVE
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      </Modal>
                    </Row>
                    <br />
                    <div style={{ maxWidth: "97%" }}>
                      <Paragraph
                        className={classes.eventDetails + " scroll"}
                        ellipsis={
                          ellipsis
                            ? { rows: 2, expandable: true, symbol: "more" }
                            : false
                        }
                      >
                        {details?.description}
                      </Paragraph>
                    </div>
                    <Row className={classes.nominationDate}>
                      <div>Nomination Start Date:</div>
                      <div>
                        <b>{details?.actualDate}</b>
                      </div>
                      <div 
                      // style={{ marginLeft: "30px" }}
                      >
                        Nomination Final Date:
                      </div>
                      <div>
                        <b>{details?.nominationDate}</b>
                      </div>
                    </Row>
                    <Row className={classes.eventTypeInfo}>
                      <div className={classes.greenDot} />
                      <div className={classes.eventType}>
                        {details?.eventType}
                      </div>
                      <div style={{ margin: "3px 0px 0 20px" }}>File Type:</div>
                      <div style={{ margin: "3px 0px 0 0px" }}>
                        <b>
                          {details?.fileType === "application"
                            ? "Pdf"
                            : details?.fileType}
                        </b>
                      </div>
                      <div style={{ margin: "3px 0px 0 20px" }}>Location:</div>
                      <div style={{ margin: "3px 0px 0 0px" }}>
                        <b>{details?.location}</b>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={5} offset={1}>
                {winnerLoader ? (
                  <>
                    {width > 700 && <Row justify="center"> <Spin size="large" style={{ alignSelf: "center", height: "10vh", marginTop: "10vh", }} /> </Row>}
                    {width < 700 && <Row justify='center' style={{ width: '100vw', height: '100vh', }}><Spin size='large' style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} /></Row>}

                  </>
                ) : (
                  <>
                    {winnerSlected?.length !== 0 ? (
                      <div className={classes.mainWinnerDiv}>
                        {winnerSlected?.map((val, index) => {
                          let card;
                          let color;
                          if (val?.house === "AGNI") {
                            card = AgniCard;
                            color = "#ECB189";
                          } else if (val?.house === "VAYU") {
                            card = VayuCard;
                            color = "#F8EECB";
                          } else if (val?.house === "PRITHVI") {
                            card = PrithviCard;
                            color = "#CAE0D2";
                          } else if (val?.house === "AKASH") {
                            card = AkashCard;
                            color = "#AA8FB5";
                          } else if (val?.house === "JAL") {
                            card = JalCard;
                            color = "#A3D6F0";
                          }
                          return (
                            <div
                              style={{
                                display: "flex",
                                gap: "7px",
                                background: `url(${card})`,
                                backgroundColor: color,
                                alignItems: "center",
                                padding: "8px 12px",
                              }}
                            >
                              <div>{val?.position.slice(0, 3)}</div>
                              <img
                                src={
                                  val?.profileImage !== null
                                    ? val?.profileImage
                                    : profileIcon
                                }
                                alt="profile"
                                width="40px"
                                height="40px"
                                style={{ borderRadius: "50%" }}
                              ></img>
                              <div>{val?.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className={classes.mainWinnerDiv}>
                        <Row justify="center">
                          {user.role === "SuperAdmin" ||
                            user.privilege === "Admin" ||
                            user.privilege === "EventOrganisers" ? (
                            <span
                              className={classes.selectWinner}
                              onClick={() => setSelectWinner(true)}
                            >
                              {" "}
                              Select Event Winners +
                            </span>
                          ) : (
                            <span className={classes.selectWinner}>
                              {" "}
                              Event Winners
                            </span>
                          )}

                          <Modal
                            centered
                            open={selectWinner}
                            onCancel={() => setSelectWinner(false)}
                            closable={true}
                            header={null}
                            footer={null}
                            destroyOnClose={true}
                          >
                            <SelectWinnerModal
                              eventId={
                                values?.eventId !== undefined
                                  ? values?.eventId
                                  : values?.id
                              }
                              eventType={details?.eventType}
                              setSelectWinner={setSelectWinner}
                              setSubmitWinner={setSubmitWinner}
                            />
                          </Modal>
                        </Row>
                      </div>
                    )}{" "}
                  </>
                )}
              </Col>
            </Row>}

            {width < 700 &&
              <div className={classes.mainEventRow}>
                <div className={classes.eventCol}>
                  <div className={classes.mainEventRow2}>
                    <div >
                      <img
                        src={details?.media[0]?.[0]}
                        className={classes.eventImage}
                        alt=""
                      />
                    </div>
                    <div className={classes.eventInfo}>
                      <div className={classes.startDate}>
                        <div>
                          <div>
                            <span>Start date:</span>
                            <b>{details?.startDate}</b>
                          </div>
                          <div>
                            <span>End date:</span>
                            <b>{details?.endDate}</b>
                          </div>
                        </div>
                        {(user.role === "SuperAdmin" ||
                          user.privilege === "Admin" ||
                          user.privilege === "EventOrganisers") && (
                            <img
                              src={EditButton}
                              onClick={() => {if(winnerSlected.length > 0){}else{setModalOpen(true)}}}
                              style={{
                                width: "15px",
                                height: "15px",cursor:winnerSlected.length > 0 ? "not-allowed" :"pointer"
                                // display: "flex",
                              }}
                            ></img>
                          )}

                        {
                          modalOpen && (
                            <div className="modal-container" style={{ padding: '0px 12px 0px 12px', width: '90%', height: '80vh', zIndex: '1000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', justifyContent: 'center', alignItems: 'center', background: 'white', display: 'flex', boxShadow: 'rgb(22 23 23 / 77%) 5px 0px 48px 137px', borderRadius: '4px' }} >
                              <div className="modal-content">
                                <div style={{ marginTop: '-10px', marginBottom: '12px', height: '5vh', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <div style={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '600', }} >Edit Event</div>
                                  <img src={x} style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={() => setModalOpen(false)} />
                                </div>
                                <div className="modal-body" style={{ overflow: 'auto', maxHeight: 'calc(80vh - 2 * 20px - 45px)' }}>
                                  <Form layout="vertical" form={form} className={classes.modalDiv} id="editEventForm" onFinish={editEventHandler} initialValues={{ title: details?.title, description: details?.description, firstWinnerPoints: details?.firstWinnerPoints, secondWinnerPoints: details?.secondWinnerPoints, thirdWinnerPoints: details?.thirdWinnerPoints, actualDate: moment(details?.actualDate), nominationDate: moment(details?.nominationDate), startDate: moment(details?.startDate), endDate: moment(details?.endDate), }} >
                                    <div>
                                      <Form.Item label="Name"
                                        name="title"
                                        className={classes.nameDiv}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please enter Event Name",
                                          },
                                        ]}
                                      >
                                        <Input
                                          type="text"
                                          placeholder="Enter name of the event"
                                        />
                                      </Form.Item>
                                      <Row className={classes.dateDiv}>
                                        <Form.Item
                                          label="Nomination Start Date"
                                          name="actualDate"
                                          className={classes.firstDateDiv}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter Nomination Start Date",
                                            },
                                          ]}
                                          style={{ position: "relative" }}
                                        >
                                          <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder="dd-mm-yyyy"
                                            format={"YYYY-MM-DD"}
                                            placement="bottomRight"
                                            getPopupContainer={(trigger) =>
                                              trigger.parentElement
                                            }
                                            onChange={(date, dateString) => {
                                              setNominationEnddate(dateString);
                                            }}
                                            disabledDate={(current) => {
                                              return (
                                                current &&
                                                current < moment().startOf("day")
                                              );
                                            }}
                                          ></DatePicker>
                                        </Form.Item>

                                        <Form.Item
                                          label="Nomination Final Date"
                                          name="nominationDate"
                                          className={classes.firstDateDiv}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please enter Nomination End Date",
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder="dd-mm-yyyy"
                                            format={"YYYY-MM-DD"}
                                            placement="bottomRight"
                                            getPopupContainer={(trigger) =>
                                              trigger.parentElement
                                            }
                                            disabledDate={(current) => {
                                              return (
                                                current &&
                                                current <
                                                moment(
                                                  NominationStartDate,
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            onChange={(date, dateString) => {
                                              {
                                                setNominationDate(dateString);
                                              }
                                            }}
                                          ></DatePicker>
                                        </Form.Item>
                                      </Row>

                                      <Row className={classes.dateDiv}>
                                        <Form.Item
                                          label="Event Start Date"
                                          name="startDate"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please enter Event Start Date",
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder="dd-mm-yyyy"
                                            format={"YYYY-MM-DD"}
                                            className={classes.firstDateDiv}
                                            placement="bottomRight"
                                            getPopupContainer={(trigger) =>
                                              trigger.parentElement
                                            }
                                            onChange={(date, dateString) => {
                                              setStartdate(dateString);
                                            }}
                                            disabledDate={(current) => {
                                              return (
                                                current &&
                                                current <
                                                moment(nominationdates, "YYYY-MM-DD")
                                              );
                                            }}
                                          ></DatePicker>
                                        </Form.Item>

                                        <Form.Item
                                          label="Event End Date"
                                          name="endDate"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please enter Event End Date",
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder="dd-mm-yyyy"
                                            format={"YYYY-MM-DD"}
                                            className={classes.firstDateDiv}
                                            placement="bottomRight"
                                            getPopupContainer={(trigger) =>
                                              trigger.parentElement
                                            }
                                            onChange={(date, dateString) => {
                                              setEnddate(dateString);
                                            }}
                                            disabledDate={(current) => {
                                              return (
                                                current &&
                                                current < moment(startDate, "YYYY-MM-DD")
                                              );
                                            }}
                                          ></DatePicker>
                                        </Form.Item>
                                      </Row>
                                      <Form.Item
                                        label=" Description"
                                        name="description"
                                        className={classes.nameDiv}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please enter Description",
                                          },
                                        ]}
                                      >
                                        <TextArea
                                          type="textareat"
                                          placeholder="type here"
                                          rows={3}
                                        />
                                      </Form.Item>
                                      <Row className={classes.dateDiv}>
                                        <Form.Item
                                          label="Points for 1st winner"
                                          name="firstWinnerPoints"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter points for 1st winner",
                                            },
                                          ]}
                                        >
                                          <Input
                                            type="number"
                                            min={0}
                                            onChange={(value) => {
                                              setWinner1st(value.target.value);
                                            }}
                                          ></Input>
                                        </Form.Item>

                                        <Form.Item
                                          label="Points for 2nd winner"
                                          name="secondWinnerPoints"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter points for 2nd winner",
                                            },
                                          ]}
                                        >
                                          <Input
                                            type="number"
                                            min={0}
                                            onChange={(value) =>
                                              setWinner2nd(value.target.value)
                                            }
                                          ></Input>
                                        </Form.Item>
                                      </Row>

                                      <Row className={classes.dateDiv}>
                                        <Form.Item
                                          label="Points for 3rd winner"
                                          name="thirdWinnerPoints"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter points for 3rd winner",
                                            },
                                          ]}
                                        >
                                          <Input
                                            type="number"
                                            min={0}
                                            onChange={(value) =>
                                              setWinner3rd(value.target.value)
                                            }
                                          ></Input>
                                        </Form.Item>

                                        <Form.Item>
                                          <b>Total points allocated- </b>{" "}
                                          {parseInt(winner1st) +
                                            parseInt(winner2nd) +
                                            parseInt(winner3rd)}
                                        </Form.Item>
                                      </Row>
                                      <Form.Item className={classes.buttonDiv}>
                                        <Button
                                          onClick={() => {
                                            setModalOpen(false);
                                            document.forms[0].reset();
                                          }}
                                        >
                                          DISCARD
                                        </Button>
                                        <Button
                                          className={classes.addButton}
                                          htmlType="submit"
                                        >
                                          SAVE
                                        </Button>
                                      </Form.Item>
                                    </div>
                                  </Form>
                                </div>
                              </div>
                            </div>)}
                      </div>

                      <div >
                        <Paragraph
                          className={classes.eventDetails + " scroll"}
                          ellipsis={
                            ellipsis
                              ? { rows: 2, expandable: true, symbol: "more" }
                              : false
                          }
                        >
                          {details?.description}
                        </Paragraph>
                      </div>

                      <Row className={classes.nominationDate}>
                        <div>
                          <span>Nomination Start Date:</span>
                          <b>{details?.actualDate}</b>
                        </div>

                        <div>
                          <span>Nomination Final Date:</span>
                          <b>{details?.nominationDate}</b>
                        </div>
                      </Row>

                      <Row className={classes.eventTypeInfo}>
                        <div className={classes.greenDot} />
                        <div className={classes.eventType}>
                          {details?.eventType}
                        </div>
                        <div style={{ margin: "3px 0px 0 20px" }}>File Type: <b> {details?.fileType === "application" ? "Pdf" : details?.fileType} </b>
                        </div>
                        {/* <div style={{ margin: "3px 0px 0 0px" }}>
                          <b> {details?.fileType === "application" ? "Pdf" : details?.fileType} </b>
                        </div> */}
                        <div style={{ margin: "3px 0px 0 20px" }}>Location: <b>{details?.location}</b></div>
                        {/* <div style={{ margin: "3px 0px 0 0px" }}>
                          <b>{details?.location}</b>
                        </div> */}
                      </Row>
                    </div>
                  </div>
                </div>

                <div>
                  {winnerLoader ? (
                    <Row justify="center">
                      <Spin
                        size="large"
                        style={{
                          alignSelf: "center",
                          height: "10vh",
                          marginTop: "10vh",
                        }}
                      />
                    </Row>
                  ) : (
                    <>
                      {" "}
                      {winnerSlected?.length !== 0 ? (
                        <div className={classes.mainWinnerDiv}>
                          {winnerSlected?.map((val, index) => {
                            let card;
                            let color;
                            if (val?.house === "AGNI") {
                              card = AgniCard;
                              color = "#ECB189";
                            } else if (val?.house === "VAYU") {
                              card = VayuCard;
                              color = "#F8EECB";
                            } else if (val?.house === "PRITHVI") {
                              card = PrithviCard;
                              color = "#CAE0D2";
                            } else if (val?.house === "AKASH") {
                              card = AkashCard;
                              color = "#AA8FB5";
                            } else if (val?.house === "JAL") {
                              card = JalCard;
                              color = "#A3D6F0";
                            }
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "7px",
                                  background: `url(${card})`,
                                  backgroundColor: color,
                                  alignItems: "center",
                                  padding: "8px 12px",
                                }}
                              >
                                <div>{val?.position.slice(0, 3)}</div>
                                <img
                                  src={
                                    val?.profileImage !== null
                                      ? val?.profileImage
                                      : profileIcon
                                  }
                                  alt="profile"
                                  width="40px"
                                  height="40px"
                                  style={{ borderRadius: "50%" }}
                                ></img>
                                <div>{val?.name}</div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className={classes.mainWinnerDiv}>
                          <Row justify="center">
                            {user.role === "SuperAdmin" ||
                              user.privilege === "Admin" ||
                              user.privilege === "EventOrganisers" ? (
                              <span
                                className={classes.selectWinner}
                                onClick={() => setSelectWinner(true)}
                              >
                                {" "}
                                Select Event Winners +
                              </span>
                            ) : (
                              <span className={classes.selectWinner}>
                                {" "}
                                Event Winners
                              </span>
                            )}

                            <Modal
                              centered
                              open={selectWinner}
                              onCancel={() => setSelectWinner(false)}
                              closable={true}
                              header={null}
                              footer={null}
                              destroyOnClose={true}
                            >
                              <SelectWinnerModal
                                eventId={
                                  values?.eventId !== undefined
                                    ? values?.eventId
                                    : values.id
                                }
                                eventType={details?.eventType}
                                setSelectWinner={setSelectWinner}
                                setSubmitWinner={setSubmitWinner}
                              />
                            </Modal>
                          </Row>
                        </div>
                      )}{" "}
                    </>
                  )}
                </div>
              </div>}
          </div>

          {width > 700 && <Row style={{ margin: "20px 0px 0px 50px", }}>
            <Col span={5}>
              {user?.privilege === "Admin" ||
                user?.role === "SuperAdmin" ||
                user?.privilege === "EventOrganisers" ? (
                <div
                  // onClick={() => setModVis(true)}
                  style={{cursor:winnerSlected.length > 0 ? "not-allowed" :"pointer"}}
                  className={classes.creategroupBtn}
                  onClick={() => {if(winnerSlected.length > 0){}else{setModVis(true)}}}
                >
                  ADD EVENT IMAGES
                </div>
              ) : (
                ""
              )}
            </Col>

            <Col span={4} offset={14}>
              {console.log("roleee", user?.role)}
              {(user?.privilege === "EventCoordinators" || user?.role === 'SuperAdmin' || showCreateGrp) &&
                (details?.eventType === "Both" || details?.eventType === "Group") ? (
                <div className={classes.creategroupBtn} style={{ marginLeft: "-20%",cursor:winnerSlected.length > 0 ? "not-allowed" :"pointer" }} 
                onClick={() => {
                  if(winnerSlected.length > 0){

                  }else{
                  setGrpModal(true);
                  console.log("oooo", participantArray?.length)
                  if (participantArray?.length === 0) {
                    setGrpModal(false);
                    Notification.error(
                      "Error","There is no participant left to create group"

                    );
                  }
                 }
                }}
                >
                  Create Group
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>}

          {width < 700 &&
            <Row style={{ margin: "1rem 0 2rem 0", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",}}>

              {user?.privilege === "Admin" ||
                user?.role === "SuperAdmin" ||
                user?.privilege === "EventOrganisers" ? (
                <div style={{cursor:winnerSlected.length > 0 ? "not-allowed" :"pointer"}}
                  className={classes.creategroupBtn}
                  onClick={() => {if(winnerSlected.length > 0){}else{setModVis(true)}}}
                >
                  ADD EVENT IMAGES
                </div>
              ) : (
                ""
              )}
              {(user?.privilege === "EventCoordinators" || showCreateGrp) &&
                (details?.eventType === "Both" ||
                  details?.eventType === "Group") ? (
                <div
                  className={classes.creategroupBtn}
                  onClick={() => {
                    setGrpModal(true);
                    if (participantArray?.length === 0) {
                      setGrpModal(false);
                      Notification.error(
                        "Error",                        
                        "There is no participant left to create group"

                      );
                    }
                  }}
                >
                  Create Group
                </div>
              ) : (
                ""
              )}

            </Row>
          }

          <Row
            className="ImageUpload"
            justify="start"
            style={{ margintop: "50px", width: "40vw" }}
          >
            <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={onSelect}
            />
          </Row>

          <Modal
            open={modVis}
            onCancel={() => {
              setModVis(false);
              setFileList([]);
            }}
            footer={false}
            destroyOnClose={true}
          >
            <Row>
              <Upload
                accept=".png, .jpg, .jpeg"
                id="Upload"
                listType="picture-card"
                fileList={fileList}
                showUploadList={{
                  showPreviewIcon: false,
                }}
                onChange={handleChange}
              >
                {fileList?.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
                destroyOnClose={true}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Row>

            <Row justify="center">
              <Button
                size="large"
                style={{ color: "#B2B2B2", borderColor: "#B2B2B2" }}
                onClick={() => {
                  setFileList([]);
                  setModVis(false);
                }}
              >
                DISCARD
              </Button>

              <Button
                size="large"
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#6FC78F",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  let value = [];
                  if (fileList.length === 0) {
                    message.error("Please Add Image");
                    return;
                  }
                  value = fileList?.map((element) => {
                    return element.originFileObj;
                  });
                  Api.post("/ibelong-posts").upload(
                    { eventId: details?.id, files: value },
                    (percentCompleted, response) => {
                      if (percentCompleted === 100) {
                        setFileList([]);
                        setModVis(false);
                        getApi();
                      }
                    }
                  );
                }}
              >
                ADD
              </Button>
            </Row>
          </Modal>

          {details?.nominationStatus ? (
            <>
              {allParticpants !== true && details?.eventType !== "none" ? (
                ""
              ) : (
                <Card style={{ width: width > 700 ? "500px" : "100%", margin: width > 700 ? "20px 0px 0px 50px" : "0.5rem auto" }}>
                  <>
                    Interested to go to the events? Click below to get the
                    updates
                    <Row style={{ marginTop: "20px" }} justify="center">
                      <Space>
                        <Button
                          style={{ paddingLeft: "40px", paddingRight: "40px" }}
                          onClick={() => {
                            // setAllparticipants(false);
                            Api.post("/ibelong-events/participate")
                              .params({
                                eventId: details?.id,
                                stage: "NotInterested",
                              })
                              .send((res) => {
                                getApi();
                              });
                          }}
                        >
                          No
                        </Button>
                        <Button
                          loading={participantbtnLoader}
                          style={{ backgroundColor: "#6FC78F", color: "white" }}
                          onClick={() => {
                            setbtnedLoader(true);
                            if (details?.eventType !== "Both") {
                              Api.post("/ibelong-events/participate")
                                .params({
                                  eventId: details?.id,
                                  stage: "Nominee",
                                  performanceType: details?.eventType,
                                })
                                .send((res) => {
                                  setbtnedLoader(false);
                                  getApi();
                                  // getGroups();
                                });
                            } else {
                              setEventType(true);
                            }
                          }}
                        >
                          Interested
                        </Button>
                      </Space>
                    </Row>
                  </>
                </Card>
              )}
            </>
          ) : (
            ""
          )}

          {width > 700 && <main className={classes.main} style={{ marginLeft: "50px", }}>
            <div className={classes.search}>
              <div>
                <h1>Participants</h1>
              </div>

            </div>

            <div className={classes.tableHeader}> </div>
            {details?.eventType === "Both" ? (
              <Tabs onChange={(key) => {
                console.log("download1tab")
                setDownloadData(key);
                setTab(key);
                if (key === "Group") {
                  setGroup("Group");
                  setHideDownload(true);
                }
                if (key === "Groups") {
                  setHideDownload(false);
                  setIndividual(false);
                  getGroups();
                  setGroup("Group");
                }
                else if (key === "individual") {
                  setHideDownload(true);
                  setGroup("Individual");
                  setIndividual(true);
                  getApi();
                }
              }}
              >
                <TabPane tab={<b style={{ font: "normal normal bold 18px Open Sans" }}>  Individual </b>} key="individual"  >
                  <Row style={{ width: '100%', marginBottom: '5px', justifyContent: 'end' }}>
                    <div className={classes.functionDiv}>
                      {hideDownload && (
                        <Button className={classes.downloadBtn} style={{ height: "30px", marginBottom: "9px", }}
                          onClick={(e) => {
                            Api.get(`/ibelong-events/eventMaster/${details?.id}`).params({ download: true, downloadtype:"Individual", search: search, }).send((response) => {
                              console.log("download1", Tab)
                              if (typeof response != "undefined") { window.open(response, "_blank")?.focus(); }
                            });
                          }}
                        >
                          Download User Data<DownloadOutlined />
                        </Button>
                      )}
                      <SearchCompo value={search} setSearch={setSearch} maxWidth="360px" />
                    </div>
                  </Row>
                  <Table
                    columns={columns}
                    dataSource={event}
                    loading={gpLoader}
                    rowClassName={classes.tableStyle}
                    style={{ fontFamily: "poppins", fontSize: "16px" }}
                    pagination={{ total: event?.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} participants`, }} />
                </TabPane>
                <TabPane tab={<b style={{ font: "normal normal bold 18px Open Sans" }}>  Group Participants </b>} key="Group"  >
                  <Row style={{ width: '100%', marginBottom: '5px', justifyContent: 'end' }}>
                    <div className={classes.functionDiv}>
                      {hideDownload && (
                        <Button className={classes.downloadBtn} style={{ height: "30px", marginBottom: "9px", }}
                          onClick={(e) => {
                            Api.get(`/ibelong-events/eventMaster/${details?.id}`).params({ download: true, downloadtype:"Group", search: search, }).send((response) => {
                              console.log("download11")
                              if (typeof response != "undefined") { window.open(response, "_blank")?.focus(); }
                            });
                          }}
                        >
                          Download User Data<DownloadOutlined />
                        </Button>
                      )}
                      <SearchCompo value={search} setSearch={setSearch} maxWidth="360px" />
                    </div>
                  </Row>
                  <Table
                    columns={columnsTable}
                    dataSource={groupParticipant}
                    loading={gpLoader}
                    rowClassName={classes.tableStyle}
                    style={{ fontFamily: "poppins", fontSize: "16px" }}
                    pagination={{ total: groupParticipant?.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} participants`, }} />
                </TabPane>
                <TabPane tab={<b style={{ font: "normal normal bold 18px Open Sans" }}>  Groups </b>}
                  key="Groups" >
                  <Table
                    key={Math.random()}
                    columns={columnsGroup}
                    dataSource={groupList}
                    loading={grouploader}
                    rowClassName={classes.tableStyle}
                    style={{ fontFamily: "poppins", fontSize: "16px" }}
                    pagination={{
                      total: groupList?.length,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} groups`,
                    }}
                  />
                </TabPane>
              </Tabs>
            ) : details?.eventType === "Group" ? (
              <>
                <Tabs onChange={(key) => {
                  setTab(key);
                  if (key === "groupParticipant") {
                    console.log("Junia groupParticipant", key)
                    setGroup("Group");
                    setHideDownload(true);
                  }
                  if (key === "Groups") {
                    console.log("Junia Groups", key)
                    setHideDownload(false);
                    setIndividual(false);
                    getGroups();
                  } else {
                    setIndividual(true);
                    getApi();
                  }
                }}
                >
                  <TabPane
                    tab={<b style={{ font: "normal normal bold 18px Open Sans" }}>Groups Participant</b>}
                    key="groupParticipant"
                  >
                    <Row style={{ width: '100%', marginBottom: '5px', justifyContent: 'end' }}>
                      <div className={classes.functionDiv}>
                        {hideDownload && (
                          <Button className={classes.downloadBtn} style={{ height: "30px", marginBottom: "9px", }}
                            onClick={(e) => {
                              Api.get(`/ibelong-events/eventMaster/${details?.id}`).params({ download: true, downloadtype:"Group", search: search, }).send((response) => {
                                console.log("download111")
                                if (typeof response != "undefined") { window.open(response, "_blank")?.focus(); }
                              });
                            }}
                          >
                            Download User Data<DownloadOutlined />
                          </Button>
                        )}
                        <SearchCompo value={search} setSearch={setSearch} maxWidth="360px" />
                      </div>
                    </Row>
                    <Table
                      columns={columnsTable}
                      dataSource={groupParticipant}
                      loading={gpLoader}
                      rowClassName={classes.tableStyle}
                      style={{ fontFamily: "poppins", fontSize: "16px" }}
                      pagination={{ total: groupParticipant?.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} participants` }}
                    />

                  </TabPane>
                  <TabPane tab={<b style={{ font: "normal normal bold 18px Open Sans" }}>  Groups  </b>} key="Groups" >
                    <Table
                      columns={columnsGroup}
                      dataSource={groupList}
                      loading={grouploader}
                      rowClassName={classes.tableStyle}
                      style={{ fontFamily: "poppins", fontSize: "16px" }}
                      pagination={{ total: groupList?.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} groups`, }} />
                  </TabPane>
                </Tabs>
              </>
            ) : (
              <>
                <Row style={{ width: '100%', marginBottom: '5px', justifyContent: 'end' }}>
                  <div className={classes.functionDiv}>
                    {hideDownload && (
                      <Button className={classes.downloadBtn} style={{ height: "30px", marginBottom: "9px", }}
                        onClick={(e) => {
                          Api.get(`/ibelong-events/eventMaster/${details?.id}`).params({ download: true, downloadtype: group, search: search, }).send((response) => {
                            console.log("download1111")
                            if (typeof response != "undefined") { window.open(response, "_blank")?.focus(); }
                          });
                        }}
                      >
                        Download User Data<DownloadOutlined />
                      </Button>
                    )}
                    <SearchCompo value={search} setSearch={setSearch} maxWidth="360px" />
                  </div>
                </Row>
                <Table
                  columns={columns}
                  dataSource={event}
                  rowClassName={classes.tableStyle}
                  style={{ fontFamily: "poppins", fontSize: "16px" }}
                  pagination={{ total: event?.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} participants`, }} />
              </>
            )}
          </main>}

          {width < 700 && <main className={classes.main}>
            <div className={classes.search}>
              <div>
                <h1>Participants</h1>
              </div>

              <div className={classes.functionDiv}>
                {hideDownload && (
                  <Button type="primary" onClick={(e) => {
                    Api.get(`/ibelong-events/eventMaster/${details?.id}`)
                      .params({ download: true, downloadtype: group, })
                      .send((response) => {
                        console.log("grrr1122")
                        if (typeof response != "undefined") {
                          window.open(response, "_blank")?.focus();
                        }
                      });
                  }}
                  >
                    {width > 700 && <>Download User Data <DownloadOutlined /></>}
                    {width < 700 && <div style={{ fontSize: '11px' }}>Download User Data <DownloadOutlined /></div>}
                  </Button>
                )}
                {width < 700 && <div style={{ width: '95vw' }}>
                  <SearchCompo value={search} setSearch={setSearch} />
                </div>}
                {width > 700 && <div style={{}}>
                  <SearchCompo value={search} setSearch={setSearch} />
                </div>}
              </div>
            </div>

            {details?.eventType === "Both" ? (
              <Tabs
                onChange={(key) => {
                  console.log("download1tab")
                  setDownloadData(key);
                  if (key === "Group") {
                    setGroup("Group");
                    setHideDownload(true);
                  }
                  if (key === "Groups") {
                    setHideDownload(false);
                    setIndividual(false);
                    getGroups();
                  } else if (key === "individual") {
                    setHideDownload(true);
                    setGroup("Individual");
                    setIndividual(true);
                    getApi();
                  }
                }}
              >
                <TabPane
                  tab={
                    <span className={classes.tabName}>
                      Individual
                    </span>
                  }
                  key="individual"
                >


                  <div>
                    {event?.map((item, index) => {
                      return (
                        <IndividualMobData key={index} item={item} user={user} details={details} getApi={getApi} getApiWithSearch={getApiWithSearch} optional={optional} setVideoModal={setVideoModal} setOptional={setOptional} setUploaderId={setUploaderId} setAddMedia={setAddMedia} />
                      )
                    })}
                  </div>



                </TabPane>
                <TabPane
                  tab={
                    <span className={classes.tabName}>
                      Groups Participant
                    </span>
                  }
                  key="Group"
                >

                  <div>
                    {groupParticipant?.map((item, index) =>
                      <GroupParticipantMob key={index} item={item} getApi={getApi} user={user} details={details} />
                    )}
                  </div>

                </TabPane>
                <TabPane
                  tab={
                    <span className={classes.tabName}>
                      Groups
                    </span>
                  }
                  key="Groups"
                >

                  <div>
                    {groupList?.map((item, index) => {
                      return (
                        <GroupMob key={index} setGroupDetails={setGroupDetails} groupRole={groupRole} item={item} user={user} details={details} getGroups={getGroups} getApi={getApi} optional={optional} setVideoModal={setVideoModal} setOptional={setOptional} setUploaderId={setUploaderId} setAddMedia={setAddMedia} setGroupOpenmodal={setGroupOpenmodal} setId={setId} />
                      )
                    })}
                  </div>

                </TabPane>
              </Tabs>
            ) : details?.eventType === "Group" ? (
              <>
                <Tabs
                  onChange={(key) => {
                    setTab(key);
                    if (key === "groupParticipant") {
                      setGroup("Group");
                      setHideDownload(true);
                    }
                    if (key === "Groups") {
                      setHideDownload(false);
                      setIndividual(false);
                      getGroups();
                    } else {
                      setIndividual(true);
                      getApi();
                    }
                  }}
                >
                  <TabPane
                    tab={
                      <span className={classes.tabName}>
                        Groups Participant
                      </span>
                    }
                    key="groupParticipant"
                  >


                    <div>
                      {groupParticipant?.map((item, index) =>
                        <GroupParticipantMob key={index} item={item} getApi={getApi} user={user} details={details} />
                      )}
                    </div>

                  </TabPane>
                  <TabPane
                    tab={
                      <span className={classes.tabName}>
                        Groups
                      </span>
                    }
                    key="Groups"
                  >

                    <div>
                      {groupList?.map((item, index) => {
                        return (
                          <GroupMob key={index} setGroupDetails={setGroupDetails} item={item} user={user} details={details} getGroups={getGroups} getApi={getApi} optional={optional} setVideoModal={setVideoModal} setOptional={setOptional} setUploaderId={setUploaderId} setAddMedia={setAddMedia} setGroupOpenmodal={setGroupOpenmodal} setId={setId} />
                        )
                      })}
                    </div>


                  </TabPane>
                </Tabs>
              </>
            ) : (

              <div>
                {event?.map((item, index) => {
                  return (
                    <IndividualMobData key={index} item={item} user={user} details={details} getApi={getApi} optional={optional} setVideoModal={setVideoModal} setOptional={setOptional} setUploaderId={setUploaderId} setAddMedia={setAddMedia} setGroupDetails={setGroupDetails}  />
                  )
                })}
              </div>
            )}
          </main>}

          {details?.fileType === "none" ? (
            ""
          ) : details?.fileType === "application" ? (
            <>
              {console.log("uuuuu", groupRole)}
              <UploadPdfDocs
                videoModal={videoModal}
                setVideoModal={setVideoModal}
                details={details}
                refresh={getApi.bind(this)}
                refreshed={getGroups.bind(this)}
                media={addMedia}
                setAddMedia={setAddMedia}
                groupId={groupId}
                uploaderId={uploaderId}
                Tab={Tab}
                groupLeaderUpload={groupLeaderUpload}
                setGroupRole={setGroupRole}
                groupRole={groupRole}
                event={event}
                setEvent={setEvent}
                groupDetails={groupDetails}
              />
            </>
          ) :
            details?.fileType === "all" ? (
              <>
                <UploadAnyFile
                  videoModal={videoModal}
                  setVideoModal={setVideoModal}
                  details={details}
                  refresh={getApi.bind(this)}
                  refreshed={getGroups.bind(this)}
                  media={addMedia}
                  setAddMedia={setAddMedia}
                  groupId={groupsId}
                  setGroupId={setGroupId}
                  uploaderId={uploaderId}
                  Tab={Tab}
                  groupLeaderUpload={groupLeaderUpload}
                  setGroupRole={setGroupRole}
                  groupRole={groupRole}
                />
              </>
            ) :
              details?.fileType === "image" ? (
                <>
                  <UploadbyUserImg
                    videoModal={videoModal}
                    setVideoModal={setVideoModal}
                    details={details}
                    refresh={getApi.bind(this)}
                    refreshed={getGroups.bind(this)}
                    media={addMedia}
                    setAddMedia={setAddMedia}
                    groupId={groupId}
                    uploaderId={uploaderId}
                    Tab={Tab}
                    groupLeaderUpload={groupLeaderUpload}
                  />
                </>
              ) : (
                <>
                  {console.log("juuuuvideoModal234", uploaderId)}
                  <VideoModal
                    videoModal={videoModal}
                    setVideoModal={setVideoModal}
                    details={details}
                    refresh={getApi.bind(this)}
                    refreshed={getGroups.bind(this)}
                    media={addMedia}
                    setAddMedia={setAddMedia}
                    groupId={groupId}
                    uploaderId={uploaderId}
                    Tab={Tab}
                    groupLeaderUpload={groupLeaderUpload}
                  />
                </>
              )}
        </div>


      )}

      <ImageModal
        setImageModal={setImageModal}
        addImageModal={addImageModal}
        details={imageData}
      />
      <Modal
        open={eventType}
        footer={false}
        width="400px"
        height="400px"
        onCancel={() => {
          setEventType(false);
          setbtnedLoader(false);
        }}
      >
        <Row>
          <Title level={5}>Choose Your Participation</Title>
        </Row>
        <Radio.Group
          onChange={(e) => {
            let value = e.target.value;
            setparticipantType(value);
          }}
        >
          <Space direction="vertical">
            <Radio value={"Individual"}>Individual{console.log("parti1", Tab)}</Radio>
            <Radio value={"Group"}>Group{console.log("parti2", Tab)}</Radio>
          </Space>
        </Radio.Group>
        <Row justify="center">
          <Button
            style={{ backgroundColor: "#6FC78F", color: "white" }}
            onClick={() => {
              setbtnedLoader(true);
              Api.post("/ibelong-events/participate")
                .params({
                  eventId: details?.id,
                  performanceType: participantType,
                })
                .send((res) => {
                  setEventType(false);
                  setbtnedLoader(false);
                  getApi();
                  // getGroups();
                  if (Tab === 'Groups') { getGroups(); }
                });
            }}
            disabled={!participantType}
          >
            Submit
          </Button>
        </Row>
      </Modal>

      <GroupModal
        username={user?.role}
        setGrpModal={setGrpModal}
        groupModal={groupModal}
        participantArray={participantArray?.filter(item => item.userId !== groupLeaderId)}
        details={details}
        Tab={Tab}
        refresh={getApi.bind(this)}
        refreshed={getGroups.bind(this)}
        groupLeaderId={groupLeaderId}
      />

      {candsMedia && (
        <AssociateFiles
          candsMedia={candsMedia}
          setCandMedia={setCandMedia}
          candidateUpload={candidateUpload}
        />
      )}
      <GroupFiles
        setgroupUpload={setgroupUpload}
        groupUpload={groupUpload}
        media={addMedia}
        id={groupId}
      />
      {groupOpenmodal && (
        <Modal
          centered
          open={groupOpenmodal}
          onCancel={() => setGroupOpenmodal(false)}
          destroyOnClose={true}
          closable={true}
          header={null}
          footer={null}
        >
          <ViewGroupModal id={id} />
        </Modal>
      )}

      <Modal
        bodyStyle={{ height: 520 }}
        open={groupActionModal}
        onCancel={() => {
          setGroupActionModal(false);
        }}
        footer={false}
        closable={true}
        destroyOnClose={true}
      >
        <h2> Edit Group Events</h2>

        <EditGroupParticipants
          editGrpData={editGrpData}
          removedGrpParticipants={removedGrpParticipants}
          setGroupActionModal={setGroupActionModal}
          setRemovedGrpParticipants={setRemovedGrpParticipants}
          viewGroupInfo={viewGroupInfo}
          participantArray={participantArray}
          getApi={getApi}
          getGroups={getGroups}
          groupRole={groupRole}
        />
      </Modal>
    </div>
  );
};
export default EventDetails;